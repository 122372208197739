<template>
  <div class="pull-left" v-if="customer.blackListCount == 0">
    <b-button variant="dark" size="sm" @click="showModal">Хар жагсаалтанд нэмэх</b-button>

    <b-modal ref="my-modal" title="Хар жагсаалтанд нэмэх" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="BlackList">
        <b-form-group label="Шалтгаан:" label-for="input-change-lastname">
          <b-form-textarea id="input-change-lastname" v-model="form.reason" required
            placeholder="Бичнэ үү"></b-form-textarea>
        </b-form-group>

        <p class="text-danger pull-left mt-2 ml-1 mb-0">
          Хар жагсаалтанд нэмэхдээ итгэлтэй байна уу?
        </p>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Customer.BlackList",
  props: ["customerId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      form: {
        id: this.customerId,
        reason: "",
      },
    };
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    BlackList: function () {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/blacklist",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              // //console.log(response)
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
                this.hideModal();
                this.callback();
              } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
    },
  },
};
</script>