var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-card',[_c('h4',[_vm._v("Өнөөдөр холбогдох харилцагчид")]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Овог нэр")]),_c('th',[_vm._v("Утас")]),_c('th',[_vm._v("Үйлдэл")])])]),_vm._l((_vm.remain_list),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.customerId.personId.lastname)+" "+_vm._s(item.customerId.personId.firstname))]),_c('td',[_vm._v(_vm._s(item.customerId.personId.userId.username))]),_c('td',[_c('b-button',{attrs:{"variant":"primary","to":{
    name: 'admin.customer.detail',
    params: { id: item.customerId.id }
  },"size":"sm"}},[_c('i',{staticClass:"fa fa-id-card"})]),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.remaining(item.id, true)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Холбогдсон ")])],1)])})],2)])],1),_c('b-col',[_c('b-card',[_c('h4',[_vm._v("Хугацаандаа холбогдоогүй харилцагчид")]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Овог нэр")]),_c('th',[_vm._v("Утас")]),_c('th',[_vm._v("Товлосон огноо")]),_c('th',[_vm._v("Үйлдэл")])])]),_vm._l((_vm.remain_list_before),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.customerId.personId.lastname)+" "+_vm._s(item.customerId.personId.firstname))]),_c('td',[_vm._v(_vm._s(item.customerId.personId.userId.username))]),_c('td',[_vm._v(_vm._s(_vm._f("formatD")(item.remainDate)))]),_c('td',[_c('b-button',{attrs:{"variant":"primary","to":{
    name: 'admin.customer.detail',
    params: { id: item.customerId.id }
  },"size":"sm"}},[_c('i',{staticClass:"fa fa-id-card"})]),_c('b-button',{staticClass:"ml-2",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.remaining(item.id, false)}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Холбогдсон ")])],1)])})],2)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }