<template>
  <b-card>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <h6>
      Төхөөрөмж баталгаажуулах код
      <b-button
        size="sm"
        variant="secondary"
        class="pull-right"
        @click="getConfirmCode()"
        >Код харах</b-button
      >
      
    </h6>
    <div>
      <p class="mt-3 mb-0 ">
        <span :class="resultType">{{ result }}</span>
      </p>
    </div>
  </b-card>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axios from "axios";

export default {
  name: "Customer.PayRequest",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function() {
    return {
      isLoading: false,
      selected_type: false,
      result: "",
      resultType: "text-primary",
    };
  },
  methods: {
    getConfirmCode: function() {
      this.isLoading = true;
      this.$data.resultType = "text-primary";
      // this.$http
      //   .post(
      //     this.$config.API_URL + "CustomerWebService/get_device_confirm_code",
      //     {
      //       data: JSON.stringify({
      //         token: this.$store.getters.token,
      //         email: this.$store.getters.email,
      //         customer_id: this.customerId,
      //       }),
      //     },
      //     {
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
      axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_device_confirm_code",
            {
              email: this.$store.getters.email,
              customer_id: this.customerId,
            },
            {
            headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
            },
        })
        .then(
          (response) => {
            if (response.data.success == true) {
              this.$data.resultType = "text-primary";
              this.$data.result = response.data.data;
            } else {
              this.$data.resultType = "text-danger";
              this.$data.result = response.data.message;
            }
            this.isLoading = false;
          },
          (response) => {
            console.log(response)
            this.isLoading = false;
            this.$data.resultType = "text-danger";
            this.$data.result = "Алдаа гарсан байна";
          }
        );
    },
  },
};
</script>
