<template>
    <div>
        <h5 class="mb-3" v-html="title"></h5>
        <b-row>
            <b-col cols=3 >
                <b-card>
                    <div class="h4 m-0">{{ (this.data.complete_count.length > 0) ? this.data.complete_count[0]: 0 }} </div>
                    <small class="text-primary">{{ (this.data.complete_amount.length > 0) ? this.data.complete_amount[0]: 0 }}₮</small>
                </b-card>
            </b-col>
            <b-col cols=3>
                <Card label="1 одтой хэрэглэгч" type="STAR_COUNT_1" :data="this.data.count"></Card>
            </b-col>
            <b-col cols=3>
                <Card label="2 одтой хэрэглэгч" type="STAR_COUNT_2" :data="this.data.count" :ind="parseInt(1)"></Card>
            </b-col>
            <b-col cols=3>
                <Card label="3+ одтой хэрэглэгч" type="STAR_COUNT_3" :data="this.data.count" :ind="parseInt(2)"></Card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Card from './Card.vue'
import moment from 'moment'

export default {
    name: 'Star',
    components: {
        Card
    },
    props: {
        title: {
            type: String, 
            default: 'Эрх нэмсэн хэрэглэгч'
        },
        data: {
            type: Object || Array,
            default: () => []
        }
    }
}
</script>