<template>
  <b-row>
    <b-col cols="12">
      <b-card no-focus>
        <loading :active.sync="isLoadingDistribute" :is-full-page="false" :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"></loading>
        <h6>Хариуцаж байгаа ажилтан</h6>
        <b-list-group v-if="distribute_list.length > 0">
          <b-list-group-item v-for="(distribute, index) in distribute_list" :key="index"
            :variant="(distribute_list.length - 1 == index) ? 'primary' : 'secondary'">
            <b-badge :variant="(distribute_list.length - 1 == index) ? 'primary' : 'secondary'" pill>{{
          distribute.groupId }}</b-badge>
            {{ distribute.userName }} - <small>{{ distribute.createdDate }}</small>

          </b-list-group-item>
        </b-list-group>
        <b-alert variant="warning" show v-else>Хариуцсан ажилтан байхгүй байна </b-alert>

        <div v-if="isShow()">
          <hr>
          <b-form-group>

            <select class="form-control" v-model="distributeId" :disabled="isDisabled()">
              <option :key="index" v-for="(item, index) in employees" :value="item.id">
                @{{ item.firstname }}
              </option>
            </select>
          </b-form-group>
          <b-form-group>
            <b-checkbox v-model="isLocked" :disabled="isDisabled()">Түгжих эсэх</b-checkbox>
            <b-alert show class="mt-2 mb-0">Түгжсэн тохиолдолд хариуцсан ажилтай солигдохгүй</b-alert>
          </b-form-group>
          <b-form-group class="mb-0">
            <b-button variant="success" @click="saveRelatedUser" :disabled="isDisabled()">Хадгалах</b-button>
          </b-form-group>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
export default {
  name: "Customer.Distribute",
  props: ["customerId"],
  data: function () {
    return {
      distribute_list: [],
      noteReload: false,
      isLoadingDistribute: false,
      distributeId: null,
      selectedDistributeId: null,
      isLocked: false,
      id: this.$route.params.id,
      employees: [],
      employees_ids: []
    };
  },

  created() {
    this.loadData();
    this.getDistributeUsers()
  },

  methods: {
    getDistributeUsers: function () {
      this.isLoadingDistribute = true;
      axios.get(this.$config.NUMUR_MS_ADMIN_URL + "get_distribute_users", {
        params: { id: this.$route.params.id },
        headers: this.$store.getters.httpHeader,
      })
      .then(response => {
        console.log("Distribute users fetched");
        console.log(response);
        this.isLoadingDistribute = false;
        this.distribute_list = response.data.data;
      })
      .catch(error => {
        this.isLoadingDistribute = false;
        console.error("Error fetching distribute users:", error);
        this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
      });
    },
    loadData: function () {
      this.isLoadingDistribute = true;
      console.log(this.$store.getters.httpHeader)


      axios.get(this.$config.NUMUR_MS_ADMIN_URL + "get_distribute_user", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log(response)
            this.isLoadingDistribute = false;
            this.$data.distributeId = response.body.distributeById;
            this.$data.selectedDistributeId = response.body.distributeById
            this.$data.isLocked = response.body.isLocked;
          },
          (response) => {
            this.isLoadingDistribute = false;

          }
        );
      this.$data.noteReload = !this.$data.noteReload;


      axios.get(this.$config.NUMUR_MS_ADMIN_URL + "get_distribute_operator_list", {
        headers: this.$store.getters.httpHeader,
      })
      .then(response => {
        console.log("Distribute operators fetched");
        console.log(response);
        this.employees = response.data.data;
        this.employees_ids = this.employees.map(employee => employee.id);
      })
      .catch(error => {
        console.error("Error fetching distribute operators:", error);
      });
    },
    saveRelatedUser: function () {
  this.isLoadingDistribute = true;

  // Validate that a user has been selected when the "isLocked" flag is true
  if (this.isLocked && this.distributeId === null) {
    this.showToast("Анхаар", 'Хариуцсан ажилтан сонгоогүй байна', "warning");
    this.isLoadingDistribute = false;
    return; // Prevent further execution if validation fails
  }

  // Log the values to be sent in the request payload for debugging
  console.log("Saving distribute user data:");
  console.log({
    id: this.$route.params.id,
    user_id: this.distributeId,
    is_locked: this.isLocked
  });

  axios.post(this.$config.NUMUR_MS_ADMIN_URL + "set_distribute_user", {
    id: this.$route.params.id,
    user_id: this.distributeId,
    is_locked: this.isLocked,
  }, {
    headers: {
      ...this.$store.getters.httpHeader, // Add headers here
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    this.isLoadingDistribute = false;

    // Log the response to check the server's reply
    console.log("Server Response:", response);

    if (response.data.success) {
      this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
      this.loadData();  // Reload the data after saving
    } else {
      // Log any failure messages received from the backend
      let _ms = response.data.failureMessages.message;
      _ms.forEach(msg => {
        this.showToast("Анхаар", msg.failureMessage, "warning");
      });
    }
  })
  .catch(error => {
    this.isLoadingDistribute = false;
    console.error("Error saving related user:", error);

    // Log error details for debugging
    if (error.response) {
      console.error("Response error data:", error.response.data);
    }
    if (error.request) {
      console.error("Request error:", error.request);
    }

    this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
  });
},


    isDisabled() {
      const roles = ['ADMIN', 'SUPER_ADMIN']
      if (roles.includes(this.$store.getters.user.role.code) || this.$data.selectedDistributeId == this.$store.getters.user.id || this.$data.distribute_list.length == 0) {
        return false
      }
      return true
    },
    isShow() {
      const roles = ['ADMIN', 'SUPER_ADMIN']
      if (roles.includes(this.$store.getters.user.role.code) || this.$data.employees_ids.includes(this.$store.getters.user.id)) {
        return true
      }
      return false
    }

  },
};
</script>