<template>
  <b-row class="justify-content-md-center">
    <b-col lg="7">
      <div>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <b-row>
            <b-form @submit.stop.prevent="submitSearch" class="col-md-5">
              <b-input-group>
                <b-input-group-prepend is-text
                  ><i class="fa fa-calendar"></i
                ></b-input-group-prepend>
                <date-picker
                  v-model="startAt"
                  :config="{ format: 'YYYY-MM-DD' }"
                  placeholder="Эхлэх огноо"
                ></date-picker>
                <date-picker
                  v-model="endAt"
                  :config="{ format: 'YYYY-MM-DD' }"
                  placeholder="Дуусах огноо"
                ></date-picker>
                <b-input-group-append
                  ><b-button variant="primary" type="submit"
                    >Шүүх</b-button
                  ></b-input-group-append
                >
                <b-input-group-append
                  ><b-button variant="secondary" @click="loadData"
                    ><i class="fa fa-refresh"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-row>
        </div>
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            :hover="true"
            :small="true"
            :fixed="false"
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >
            <template v-slot:cell(message)="data">
              <div :class="{ 'text-right': data.item.type == 'received' }">
                <small>{{ data.item.date }}</small>
                <div v-if="isJson(data.item.message)">
                  <ul style="list-style: none;">
                    <li
                      :key="index"
                      v-for="(message, index) in JSON.parse(data.item.message)"
                    >
                      {{ message.title }} <br />
                      <p>{{ message.subtitle }}</p>
                    </li>
                  </ul>
                </div>
                <div v-else-if="isUrl(data.item.message)">
                  <a v-bind:href="data.item.message">{{ data.item.message }}</a>
                </div>
                <div v-else>
                  <p>{{ data.item.message }}</p>
                </div>
              </div>
            </template>
          </b-table>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "Customers.ChatBot",
  components: {
    axios,
    datePicker,
  },
  inheritAttrs: false,
  props: ["customer"],
  data: () => {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        startAt: "",
        endAt: "",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      startAt: "",
      endAt: "",
      download: {
        loading: false,
        header: {},
      },
      columns: [{ label: "Мессеж", key: "message" }],
    };
  },
  created: function() {
    this.$data.query.id = this.customerId;
    this.$data.download.header = {};
    for (let i in this.$data.columns) {
      let _field = this.$data.columns[i];
      this.$data.download.header[_field.label] = _field.key;
    }
    // this.loadData();
  },
  watch: {
    customer: {
      handler(customer) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.isLoading = true;
      this.$http
        .get(this.$config.BOT_URL + "message/" + this.customer.psid, {
          params: this.$data.query,
          //     headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // console.log(response.body.data);
            this.isLoading = false;
            this.$data.items = response.body.data;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    isJson: function(value) {
      try {
        let json = JSON.parse(value);
        return typeof json === "object";
      } catch (e) {
        return false;
      }
    },
    isUrl: function(value) {
      return value.slice(0, 4) == "http";
    },

    submitSearch: function() {
      this.$data.query.startAt = this.$data.startAt;
      this.$data.query.endAt = this.$data.endAt;
      this.loadData();
    },
  },
};
</script>
