export default {
    list: {
        columns: [
          { label: '№', key: 'index' },
          { label: 'Нэр', key: 'name' },
          { label: 'Үйлдэл', key: 'actions' }
        ],
        datas: [
          {
            name: 'Тэмдэглэлийн тайлан',
            slug: 'note',
            config: {
              // header: {
              //   '#': 'index',
              //   'Огноо': '0',
              //   'Ажилтан': '1',
              //   'Төрөл 1': '2',
              //   'Төрөл 2': '3',
              //   'Төрөл 3': '4',
              //   'Холболтын тоо': '5',
              //   'Missed call': '6',
              //   'Гар утас': '7',
              //   'Төлөх эрмэлзэл': '9',
              //   'Ангилал': '8',
              //   'Тоо': '10'
              // }
              header: {
                  '#': 'index',
                  'Огноо': 'created_date',
                  'Ажилтан': 'user_name',
                  'Төрөл 1': 'parent_name',
                  'Төрөл 2': 'note_type_name',
                  'Төрөл 3': 'child_note_type_name',
                  'Холболтын тоо': 'connection_count',
                  'Missed call': 'is_missed_call',
                  'Гар утас': 'is_mobile',
                  'Төлөх эрмэлзэл': 'is_payment_passion',
                  'Ангилал': 'category',
                  'Тоо': 'note_count'
                }
            },
          },
          {
            name: 'Тэмдэглэлийн тайлан дэлгэрэнгүй',
            slug: 'note_detail',
            config: {
              // header: {
              //   '#': 'index',
              //   'Огноо': '0',
              //   'Ажилтан': '1',
              //   'Төрөл 1': '2',
              //   'Төрөл 2': '3',
              //   'Төрөл 3': '4',
              //   'Хэрэглэгч': '11',
              //   'Холболтын тоо': '5',
              //   'Missed call': '6',
              //   'Гар утас': '7',
              //   'Төлөх эрмэлзэл': '9',
              //   'Ангилал': '8',
              //   'Тэмдэгдэл': '10'
              // }
              header: {
                '#': 'index',
                'Огноо': 'created_date',
                'Ажилтан': 'user_name',
                'Төрөл 1': 'parent_name',
                'Төрөл 2': 'note_type_name',
                'Төрөл 3': 'child_note_type_name',
                'Хэрэглэгч': 'username',
                'Холболтын тоо': 'connection_count',
                'Missed call': 'is_missed_call',
                'Гар утас': 'is_mobile',
                'Төлөх эрмэлзэл': 'is_payment_passion',
                'Ангилал': 'category',
                'Тэмдэгдэл': 'body'
              }
            }
          },
          {
            name: 'Хэрэглэгчийн мэдээлэл өөрчилсөн тайлан',
            slug: 'info_change',
            config: {
              header: {
                '#': 'index',
                'Овог': '0',
                'Нэр': '1',
                'РД': '2',
                'Төрөл': '3',
                'Хуучин утга': '4',
                'Шинэ утга': '5',
                'Тайлбар': '6',
                'Огноо': '7',
                'Ажилтан': '8',
              }
            }
          },
        ]
      }
  }
  