<template>
    <div class="animated fadeIn">
        <b-container>
            <b-row>
                <b-col>
                    <b-card>
                        <b-row v-if="loan">
                            <b-col md="6">

                                <router-link :to="{
                            name: 'admin.customer-loan-accounts.detail',
                            params: { id: this.loan.id },
                        }" target='_blank'>
                                    <h4 class="card-title text-uppercase text-primary">
                                        #{{ loan.accountNumber }}
                                    </h4>
                                </router-link>


                                <b-list-group class="mt-2">
                                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                                        <b-row>
                                            <b-col sm="6"><span class="text-muted">Төрөл</span>
                                                <h5 class="mb-0">
                                                    {{ $store.getters.loan_type[loan.typeCode] }}
                                                </h5>
                                            </b-col>
                                            <b-col sm="6" v-if="loan.merchantId"><span
                                                    class="text-muted">Merchant</span>
                                                <h5 class="mb-0">{{ loan.merchantName }}</h5>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                                        <b-row>
                                            <b-col sm="6"><span class="text-muted">Авсан огноо</span>
                                                <h5 class="mb-0">{{ loan.loanDate }}</h5>
                                            </b-col>
                                            <b-col sm="6"><span class="text-muted">Дуусах огноо</span>
                                                <h5 class="mb-0">{{ loan.expiryDate }}</h5>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                            <b-col md="6">
                                <h4 class="card-title text-uppercase text-primary">
                                    &nbsp;
                                </h4>
                                <b-list-group class="mt-2">
                                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                                        <b-row>
                                            <b-col sm="6"><span class="text-muted">Авсан дүн</span>
                                                <h5 class="mb-0">{{ loan.loanAmount }}</h5>
                                            </b-col>
                                            <b-col sm="6"><span class="text-muted">Үлдэгдэл</span>
                                                <h5 class="mb-0">{{ loan.loanBalance }} ₮</h5>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                                        <b-row>
                                            <b-col sm="6"><span class="text-muted">Тайлбар</span>
                                                <h5 class="mb-0">{{ loan.logDescription }}</h5>
                                            </b-col>
                                            <b-col sm="6"><span class="text-muted">Гэрээний дугаар</span>
                                                <h5 class="mb-0">{{ loan.contractNumber }}</h5>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-col>
                        </b-row>

                        <b-row v-else>
                            <b-col>
                                <b-alert show variant="warning" class="mb-0">
                                    Зээлийн мэдээлэл олдсонгүй
                                </b-alert>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card v-if="loan">
                        <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
                            :opacity="this.$config.LOADER.opacity"
                            :background-color="this.$config.LOADER.backgroundColor" :z-index="10000"></loading>
                        <b-form @submit.prevent="save">
                            <b-row>
                                <b-col md=6>
                                    <b-form-group label="Төрөл" label-for="type">
                                        <b-form-select id="type" v-model="selected_parent_type" required>
                                            <b-form-select-option :value="null" selected disabled>-- Сонгоно уу
                                                --</b-form-select-option>
                                            <b-form-select-option v-for="(item, key) in type_list" :key="key"
                                                :value="item">{{ item.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md=6>
                                    <b-form-group v-if="selected_parent_type" label="Төрөл" label-for="type">
                                        <b-form-select id="type" v-model="form.typeId" required>
                                            <b-form-select-option :value="null" selected disabled>-- Сонгоно уу
                                                --</b-form-select-option>
                                            <b-form-select-option v-for="(item, key) in selected_parent_type.children"
                                                :key="key" :value="item.id">{{ item.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Нэр" label-for="name">
                                        <b-form-input id="name" v-model="form.name" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <!-- {{ form }}
                    {{ attribute_list }} -->
                            <b-row>
                                <b-col v-for="(attr, index) in attribute_list" :key="index" md="6">
                                    <b-form-group v-if="attr.type == 'TEXT'" :label="attr.label" :label-for="attr.code">
                                        <b-form-input :placeholder="attr.name" v-model="attr.value"
                                            :required="attr.isRequired"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="attr.type == 'DATE'" :label="attr.label" :label-for="attr.code">
                                        <b-form-input type="date" :placeholder="attr.name" v-model="attr.value"
                                            :required="attr.isRequired"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-if="attr.type == 'NUMBER'" :label="attr.label"
                                        :label-for="attr.code">
                                        <money class="form-control" type="text" :placeholder="attr.name"
                                            v-model="attr.value" suffix="" thousands="," :precision="2"
                                            :required="attr.isRequired"></money>
                                    </b-form-group>
                                    <b-form-group v-if="attr.type == 'YEAR'" :label="attr.label" :label-for="attr.code">
                                        <money class="form-control" type="text" :placeholder="attr.name"
                                            v-model="attr.value" suffix=" он" thousands="" :precision="0"
                                            :required="attr.isRequired"></money>
                                    </b-form-group>
                                    <b-form-group v-if="attr.type == 'MONEY'" :label="attr.label"
                                        :label-for="attr.code">
                                        <money class="form-control" type="text" :placeholder="attr.name"
                                            v-model="attr.value" suffix=" ₮" thousands="," :precision="0"
                                            :required="attr.isRequired"></money>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-button type="submit" variant="primary">
                                        Хадгалах
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'; 
export default {
    name: 'NewDeposit',
    data() {
        return {
            isLoading: false,
            loan: null,
            selected_parent_type: null,
            form: {
                loan_account_id: this.$route.params.loanAccountId,
                name: null,
                typeId: null
            },
            type_list: [],
            attribute_list: [],
        };
    },
    watch: {
        'form.typeId': function (val) {
            this.loadAttribute(val)
        }
    },
    created() {
        this.loadTypeList()
    },
    methods: {
        getDeposit: function () {
    this.isLoading = true;
    const depositId = this.$route.params.id;  // Get the deposit ID from the route parameters

    // Make sure the request uses 'application/json' content type
    axios
        .get(this.$config.NUMUR_MS_ADMIN_URL + "get_deposit", {
            params: { id: depositId },  // Pass the 'id' query parameter
            headers: {
                ...this.$store.getters.httpHeader,  // Include necessary headers
                'Content-Type': 'application/json'  // Explicitly set the content type
            },
        
        })
        .then(
            (response) => {
                console.log(response);
                this.isLoading = false;
                if (response.data.success) {
                    // Handle success response
                    this.$data.form = response.data.data;
                    this.getLoanAccount(this.$data.form.loanAccountId);
                    this.setType(this.$data.form.typeId);
                    this.setValues();
                } else {
                    // Handle error in response data
                    this.showToast("Алдаа", response.data.error || "Алдаа гарсан байна", "danger");
                }
            },
            (error) => {
                this.isLoading = false;
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
        );
},
        getLoanAccount: function (loanAccountId) {
            this.isLoading = true;
            this.$http
                .get(this.$config.API_URL + "LoanWebService/get_loan_info", {
                    params: { id: loanAccountId },
                    headers: this.$store.getters.httpHeader,
                    emulateJSON: true,
                })
                .then(
                    (response) => {
                        //console.log(response)
                        this.isLoading = false;
                        this.$data.loan = response.body;
                    },
                    (response) => {
                        this.isLoading = false;
                        this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
                    }
                );
        },
        loadTypeList: function () {
    this.isLoading = true;
    axios
        .get(this.$config.NUMUR_MS_ADMIN_URL + "get_type_list", {  // Your API URL
            headers: {
                'Content-Type': 'application/json',  // Set content type to application/json
                ...this.$store.getters.httpHeader  // Include any other necessary headers, like auth headers
            },
         
        })
        .then((response) => {
            this.isLoading = false;
            if (response.data.success) {  // Check success in the response
                this.type_list = response.data.data;  // Map the data to type_list
                this.getDeposit();  // Call method to get deposit info
            } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
        })
        .catch((error) => {
            this.isLoading = false;
            console.log(error);
        });
},
loadAttribute: function (type_id) {
    this.isLoading = true;
    this.attribute_list = [];  // Clear previous attributes
    this.attribute_values = {};  // Clear previous attribute values
    // Make sure the URL matches your backend API endpoint
    axios
        .get(this.$config.NUMUR_MS_ADMIN_URL + "get_attribute_list", {
            params: {
                type_id: type_id  // Passing type_id to the backend API
            },
            headers: {
                ...this.$store.getters.httpHeader,  // Include any headers from the store
                'Content-Type': 'application/json'  // Explicitly set Content-Type to application/json
            },
            emulateJSON: true  // Make sure the response is parsed correctly
        })
        .then((response) => {
            this.isLoading = false;
            if (response.data.success && response.data.responseResultType === 'SUCCESS') {
                // If successful, update attribute_list with the data
                this.attribute_list = response.data.datas;
                this.setValues();
            } else {
                // If the response is not successful, show an error
                this.showToast("Error", response.data.error || "Алдаа гарсан байна", "danger");
            }
        })
        .catch((error) => {
            console.log(error);
            this.isLoading = false;
        });
},
        setType: function (type_id) {
            for (let i in this.type_list) {
                for (let j in this.type_list[i].children) {
                    if (this.type_list[i].children[j].id == type_id) {
                        this.selected_parent_type = this.type_list[i]
                        break
                    }
                }
            }
        },
        setValues: function () {
            for (let i in this.attribute_list) {
                console.log(this.attribute_list[i].code)
                console.log(this.getValueByCode(this.attribute_list[i].code))
                this.attribute_list[i].value = this.getValueByCode(this.attribute_list[i].code)
            }
        },
        getValueByCode: function (code) {
            for (let i in this.$data.form.values) {
                if (this.$data.form.values[i].attributeCode == code) {
                    return this.$data.form.values[i].value
                }
            }
        },
        save: function () {
    this.isLoading = true;

    // Prepare the data to send, including the attributes
    const data = {
        id: this.$route.params.id,  // The ID of the deposit
        data: {
            name: this.form.name,
            loanAccountId: this.form.loan_account_id,
            customerId: this.loan.customerId, // Assuming you have this info in the loan object
            typeId: this.form.typeId,
            attributeValues: []  // Initialize an empty array to hold attribute values
        }
    };

    // Add the attribute values to the attributeValues array
    for (let i in this.attribute_list) {
        const attr = this.attribute_list[i];
        data.data.attributeValues.push({
            attributeCode: attr.code,
            attributeValue: attr.value
        });
    }

    // Send the POST request to the Flask API
    axios
        .post(
            this.$config.NUMUR_MS_ADMIN_URL + "edit_deposit",  // The API URL
            data,  // Send the data as JSON
            {
                headers: {
                    ...this.$store.getters.httpHeader,  // Include necessary headers
                    'Content-Type': 'application/json'  // Set content type to JSON
                }
            }
        )
        .then(
            (response) => {
                this.isLoading = false;
                if (response.data.success) {
                    // Handle successful response
                    this.showToast("Амжилттай хадгалав", response.data.message || "Зээлийн мэдээлэл амжилттай шинэчлэгдсэн", "success");
                } else {
                    // Handle failure response
                    this.showToast("Алдаа", response.data.error || "Алдаа гарсан байна", "danger");
                }
            },
            (error) => {
                this.isLoading = false;
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
        );
}
    },
};
</script>

<style scoped>
/* Your component styles go here */
</style>
