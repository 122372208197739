<template>

</template>

<script>
import EditProfile from "./EditProfile.vue";
import ChangeRegister from "./ChangeRegister.vue";
import ChangeActive from "./ChangeActive.vue";
import ChangeEmail from "./ChangeEmail.vue";
import ChangeLoanActive from "./ChangeLoanActive.vue";
import ChangeLoanActiveAdmin from "./ChangeLoanActiveAdmin.vue";
import Message from "./Message.vue";
import Poll from "./Poll.vue";
import Email from "./Email.vue";
import PayRequest from "./PayRequest.vue";
import InfoLogReport from "./InfoLogReport.vue";
import ConfirmCode from "./ConfirmCode.vue";
import UserOneLoginCode from "./UserOneLoginCode.vue";
import IsSendSms from "./IsSendSms.vue";
import PhoneChangeAlert from "./PhoneChangeAlert.vue";

export default {
  name: "Customers.xypInfo",
  props: [" "],
  components: {
    EditProfile,
    ChangeRegister,
    ChangeActive,
    UserOneLoginCode,
    ChangeEmail,
    ChangeLoanActive,
    ChangeLoanActiveAdmin,
    Message,
    Email,
    Poll,
    InfoLogReport,
    ConfirmCode,
    IsSendSms,
    PhoneChangeAlert,
  },
  data: function () {
    return {
      isShow: true,
      stopInterestButtonLoading: false,
      emailButtonLoading: false,
      cibButtonLoading: false,
      noteReload: false,
      isLoading: false,
      customer: {},
      id: this.$route.params.id,
      isManualContract: 0,
      purchaseLoanRequestColumns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Огноо", key: "createdDate", class: "text-center" },
        { label: "Мерчант", key: "merchant", class: "text-center" },
        { label: "Дүн", key: "amount", class: "text-center" },
        { label: "Хүү", key: "interest", class: "text-center" },
        { label: "Төлөв", key: "status", class: "text-center" },
        { label: "Дуусах огноо", key: "expiryDate", class: "text-center" },
      ],
    };
  },
  created() {
    this.checkManualContract();
    this.checkCustomer();
  },

  methods: {
    checkCustomer: function () {
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/check_customer", {
          params: { customer_id: this.$data.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log("CHECK");
            console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.isShow = true;
              this.loadData();
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
              this.$data.isShow = false;
            }
          },
          (response) => {
            this.$data.isShow = false;
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    checkManualContract() {
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/check_manual_contract",
          { customer_id: this.$data.id },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            // //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.isManualContract = parseInt(response.body.successData);
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    loadData: function () {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_customer_info", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log('adadadadadadadadadadadad')
            console.log(response)
            this.isLoading = false;
            this.$data.customer = response.body;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;
    },
    getNoLoanHTML: function (item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/no_loan_html", {
          params: { id: item.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            if (response.body.successData) {
              mywindow.document.write(response.body.successData);

              // mywindow.print()
              // mywindow.close()
            } else {
              mywindow.document.write("Амжилтгүй");
            }

            // mywindow.document.close();
            // mywindow.open("");
            // mywindow.close('')

            // //console.log(response)
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getCustomerRepaymentSchedule: function (item) {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL +
          "CustomerWebService/get_repayment_schedule_html",
          {
            params: { id: item.id },
            emulateJSON: true,
            headers: this.$store.getters.httpHeader,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            if (response.body.successData) {
              mywindow.document.write(response.body.successData);
              setTimeout(function () {
                mywindow.print();
                mywindow.close();
              }, 300);
            } else {
              mywindow.document.write("Амжилтгүй");
            }

            // mywindow.document.close();
            // mywindow.open("");
            // mywindow.close('')

            // //console.log(response)
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sendNoLoanHTML: function (item) {
      this.emailButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/send_no_loan_html", {
          params: { id: item.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
              this.loadData();
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
            this.emailButtonLoading = false;
          },
          () => {
            this.emailButtonLoading = false;
          }
        );
    },
    updateCib: function (item) {
      this.cibButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/update_cib", {
          params: { id: item.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
            this.cibButtonLoading = false;
          },
          () => {
            this.cibButtonLoading = false;
          }
        );
    },
    setInterestStop: function (item) {
      this.stopInterestButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/set_interest_stop", {
          params: { id: item.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            console.log(response);
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
            this.stopInterestButtonLoading = false;
          },
          () => {
            this.stopInterestButtonLoading = false;
          }
        );
    },
    closedLoanLine: function (customer) {
      this.cibButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/close_loan_line", {
          params: { id: customer.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
            this.cibButtonLoading = false;
          },
          () => {
            this.cibButtonLoading = false;
          }
        );
    },
    changeContractNumber: function (_v) {
      this.$data.customer.contractedNumber = _v;
    },
    showModal() {
      // this.$data.form = {
      //   id: this.customerId,
      //   lastname: this.customer.lastname,
      //   firstname: this.customer.firstname,
      //   phone1: this.customer.phoneNumber,
      //   phone2: this.customer.emergencyNumber,
      //   type: "many_change",
      //   email1: this.customer.email,
      //   registerNumber: this.customer.registerNumber,
      // };
      this.$refs["purchase-loan-requests-modal"].show();
    },
    hideModal() {
      this.$refs["purchase-loan-requests-modal"].hide();
    },
    loadNote() {
      this.noteReload = !this.noteReload;
    },
  },
};
</script>