<template>
  <div class="dashboard-overdue">
    <div class="bg-gray-100 mr-n4 ml-n4 pl-4 pr-4 pb-1 pt-3 mb-3">
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor"
        class="mb-3 ml-n2 mr-n2" :z-index="10000"></loading>
      <div class="mb-3">
        <h4 class="pull-left">Хугацаа хэтэрсэн зээл</h4>
        <b-nav class="pull-left border-bottom-0 ml-3" tabs>
          <b-nav-item @click="changeTab('loan')" :active="tabSelect == 'loan'">Зээл</b-nav-item>
          <b-nav-item @click="changeTab('credit')" :active="tabSelect == 'credit'">Уламжлалт</b-nav-item>
        </b-nav>
        <small>
          <b-button variant="primary" size="sm" class="pull-right" @click="loadData()">
            <i class="fa fa-refresh"></i> шинэчлэх
          </b-button>
          <p class="mb-0 pull-right mr-3 text-muted" style="font-size:12px; margin-top:7px;">
            {{ dashboard.last_update_date }}
          </p>
        </small>
      </div>
      <b-table v-if="tabSelect == 'loan'" :items="dashboard.app.items" :fields="columns" bordered class="bg-white mb-4">
        <template v-slot:cell(index)="data">
          <span v-if="dashboard.app.items.length > data.index">
            {{ data.index + 1 }}
          </span>
        </template>

        <template v-slot:cell(label)="data">
          {{ data.item.label }} хоног
        </template>

        <template v-slot:cell(customer)="data">
          {{ data.item.customer | number }}
          <p class="pull-right mb-0">
            <b-badge variant="primary">{{
        dashboard.app.total.customer
          ? (data.item.customer / dashboard.app.total.customer) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>

        <template v-slot:cell(loan)="data">
          {{ data.item.loan | number }}
          <p class="pull-right mb-0">
            <b-badge variant="primary">
              {{
        dashboard.app.total.loan
          ? (data.item.loan / dashboard.app.total.loan) * 100
          : 0 | currecry
      }}%
            </b-badge>
          </p>
        </template>

        <template v-slot:cell(amount)="data">
          {{ data.item.amount | currecry }}₮
          <p class="pull-right mb-0 w-25">
            <b-badge variant="primary">{{
        dashboard.app.total.amount
          ? (data.item.amount / dashboard.app.total.amount) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>

        <template v-slot:cell(pay_amount)="data">
          {{ data.item.pay_amount | currecry }}₮
          <p class="pull-right mb-0 w-25">
            <b-badge variant="primary">{{
        dashboard.app.total.amount
          ? (data.item.pay_amount / dashboard.app.total.amount) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>
      </b-table>

      <b-table v-if="tabSelect == 'credit'" :items="dashboard.credit.items" :fields="columns" bordered
        class="bg-white mb-4">
        <template v-slot:cell(index)="data">
          <span v-if="dashboard.credit.items.length > data.index">
            {{ data.index + 1 }}
          </span>
        </template>

        <template v-slot:cell(label)="data">
          {{ data.item.label }} хоног
        </template>

        <template v-slot:cell(customer)="data">
          {{ data.item.customer | number }}
          <p class="pull-right mb-0">
            <b-badge variant="primary">{{
        dashboard.credit.total.customer
          ? (data.item.customer / dashboard.credit.total.customer) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>

        <template v-slot:cell(loan)="data">
          {{ data.item.loan | number }}
          <p class="pull-right mb-0">
            <b-badge variant="primary">
              {{
        dashboard.credit.total.loan
          ? (data.item.loan / dashboard.credit.total.loan) * 100
          : 0 | currecry
      }}%
            </b-badge>
          </p>
        </template>

        <template v-slot:cell(amount)="data">
          {{ data.item.amount | currecry }}₮
          <p class="pull-right mb-0 w-25">
            <b-badge variant="primary">{{
        dashboard.credit.total.amount
          ? (data.item.amount / dashboard.credit.total.amount) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>

        <template v-slot:cell(pay_amount)="data">
          {{ data.item.pay_amount | currecry }}₮
          <p class="pull-right mb-0 w-25">
            <b-badge variant="primary">{{
        dashboard.credit.total.amount
          ? (data.item.pay_amount / dashboard.credit.total.amount) * 100
          : 0 | currecry
      }}%</b-badge>
          </p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style>
.dashboard-overdue .nav-tabs .nav-link {
  color: #73818f;
  font-size: 20px;
  margin-top: -8px;
}
</style>

<script>
import moment from "moment";

export default {
  name: "OverdueLoan",
  components: {},
  props: {},
  data: () => {
    return {
      tabSelect: "loan",
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Хэтэрсэн хоног", key: "label" },
        { label: "Хэрэглэгч", key: "customer" },
        { label: "Зээл", key: "loan" },
        { label: "Зээлийн үлдэгдэл", key: "amount", class: "text-right" },
        { label: "Зээлийн дүн", key: "pay_amount", class: "text-right" },
      ],
      dashboard: {
        app: {
          items: [
            {
              label: "0",
              customer: "",
              loan: "",
              amount: "",
              pay_amount: "",
            },
          ],
          total: {
            customer: 0,
            loan: 0,
            amount: 0,
            pay_amount: 0,
          },
        },
        credit: {
          items: [
            {
              label: "0",
              customer: "",
              loan: "",
              amount: "",
              pay_amount: "",
            },
          ],
          total: {
            customer: 0,
            loan: 0,
            amount: 0,
            pay_amount: 0,
          },
        },
        last_update_date: null,
      },
      isLoading: false,
    };
  },
  created() {
    if (this.$store.state.dashboard_data_overdue_new != undefined) {
      this.$data.dashboard = this.$store.state.dashboard_data_overdue_new;
    }
    if (this.$data.dashboard.last_update_date == null) this.loadData();
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "ReportWebService/get_overdue_loan_type",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.dashboard.app.items = [];
            this.$data.dashboard.credit.items = [];
            var datas = response.body.data;

            for (var i in datas[0]) {
              if (datas[0][i].length > 4) {
                this.$data.dashboard.app.items.push({
                  label: datas[0][i][0],
                  customer: datas[0][i][1],
                  loan: datas[0][i][2],
                  amount: datas[0][i][3],
                  pay_amount: datas[0][i][4],
                });
              } else {
                this.$data.dashboard.app.total.customer = datas[0][i][0];
                this.$data.dashboard.app.total.loan = datas[0][i][1];
                this.$data.dashboard.app.total.amount = datas[0][i][2];
              }
            }
            for (var i in datas[1]) {
              if (datas[1][i].length > 4) {
                this.$data.dashboard.credit.items.push({
                  label: datas[1][i][0],
                  customer: datas[1][i][1],
                  loan: datas[1][i][2],
                  amount: datas[1][i][3],
                  pay_amount: datas[1][i][4],
                });
              } else {
                this.$data.dashboard.credit.total.customer = datas[1][i][0];
                this.$data.dashboard.credit.total.loan = datas[1][i][1];
                this.$data.dashboard.credit.total.amount = datas[1][i][2];
              }
            }
            this.generateData();
            this.$data.dashboard.last_update_date = moment().format(
              "YYYY-MM-DD HH:mm:ss"
            );
            this.$store.commit("ADD_STATE", {
              key: "dashboard_data_overdue_new",
              value: this.$data.dashboard,
            });
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    generateData: function () {
      let sum = {
        label: "Нийт",
        customer: 0,
        loan: 0,
        amount: 0,
        pay_amount: 0,
      };
      for (var i in this.$data.dashboard.app.items) {
        sum.customer += this.$data.dashboard.app.items[i].customer;
        sum.loan += this.$data.dashboard.app.items[i].loan;
        sum.amount += this.$data.dashboard.app.items[i].amount;
        sum.pay_amount += this.$data.dashboard.app.items[i].pay_amount;
      }
      this.$data.dashboard.app.items.push(sum);

      sum = {
        label: "Нийт",
        customer: 0,
        loan: 0,
        amount: 0,
        pay_amount: 0,
      };
      for (var i in this.$data.dashboard.credit.items) {
        sum.customer += this.$data.dashboard.credit.items[i].customer;
        sum.loan += this.$data.dashboard.credit.items[i].loan;
        sum.amount += this.$data.dashboard.credit.items[i].amount;
        sum.pay_amount += this.$data.dashboard.credit.items[i].pay_amount;
      }
      this.$data.dashboard.credit.items.push(sum);
    },
    changeTab: function (type) {
      this.$data.tabSelect = type;
    },
  },
};
</script>