<template>
  <div class="pull-left">
    <b-button variant="danger" size="sm" @click="showModal" v-if="customer.isActive">Эрх хаах</b-button>
    <b-button variant="success" size="sm" @click="showModal" v-else>Эрх нээх</b-button>

    <b-modal ref="my-modal" :title="'Эрх ' + (customer.isActive ? 'хаах' : 'нээх')" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <p class="text-danger" v-if="customer.isActive">
        Та хэрэглэгчийн эрхийг хаахдаа итгэлтэй байна уу?
      </p>
      <p class="text-danger" v-else>
        Та хэрэглэгчийн эрхийг нээхдээ итгэлтэй байна уу?
      </p>
      <b-form @submit.stop.prevent="phoneSave">
        <b-button class="pull-right" type="button" @click="hideModal" variant="danger">Болих</b-button>
        <b-button class="pull-right mr-3" type="submit" variant="primary">Тийм</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Customer.ChangePhone",
  props: ["customerId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      form: { id: this.customerId },
    };
  },
  methods: {
    phoneSave: function () {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        axios
            .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/set_change_active",
                JSON.stringify(this.$data.form),
                {
                headers: {
                    ...this.$store.getters.httpHeader, // Include your custom headers
                    "Content-Type": "application/json", // Ensure JSON content type
                },
            })
        // this.$http
        //   .post(
        //     this.$config.API_URL + "CustomerWebService/set_change_active",
        //     { data: JSON.stringify(this.$data.form) },
        //     { headers: this.$store.getters.httpHeader, emulateJSON: true }
        //   )
          .then(
            (response) => {
              this.isLoading = false;
              if (response.data.message == "success") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal();
                this.callback();
              } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
    },
    showModal() {
      this.$data.form.new_phone = "";
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>