<template>
  <b-row>
    <b-col cols="12">
      <b-card v-if="customer">
        <h3 class="mb-0">{{ customer.currentLoyaltyGroupName }} <small class="bg-info p-1 pull-right rounded">{{
        customer.currentScore }} оноо</small></h3>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
export default {
  name: "Customer.LoyaltyId",
  props: ["customerId"],
  data: function () {
    return {
      isLoading: false,
      customer: null
    };
  },

  created() {
    this.loadData();
  },

  methods: {

    loadData() {
      this.isLoading = true;
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_loyalty_info",
            {"id": this.customerId},
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.customer = response.data.data;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
  },
};
</script>