<template>
  <b-row>
    <b-col>
      <div>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="'Тэмдэгэлүүд.xls'"
            v-if="items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>

          <create
            :loanAccountId="this.loanAccountId"
            :callback="loadData"
             v-if="checkPermission('admin.customer.note-create')"
          ></create>

          <div class="pull-right mr-3">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>

          <b-row>
            <b-form
              @submit="submitSearch"
              :class="{
                'pl-0 col-md-4': items != undefined && items.length > 0,
                'col-md-4': items.length == 0,
              }"
            >
              <b-input-group>
                <b-input-group-prepend is-text
                  ><i class="fa fa-search"></i
                ></b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  placeholder="Хайх"
                ></b-form-input>
                <b-input-group-append
                  ><b-button variant="primary" type="submit"
                    >Хайх</b-button
                  ></b-input-group-append
                >
                <b-input-group-append
                  ><b-button variant="secondary" @click="loadData"
                    ><i class="fa fa-refresh"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-row>
        </div>
        <!-- {{ items}} -->
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            hover
            bordered
            small
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              <span v-if="data.index <= pagination.to - pagination.from">{{
                data.index + pagination.from
              }}</span>
            </template>

            <template v-slot:cell(body)="data">
              <p style="width:300px;" class="mb-0">{{ data.item.body }}</p>
            </template>

            <template v-slot:cell(createdBy)="data">
              <strong class="text-primary"> @{{ data.item.createdBy }} </strong>
            </template>
          </b-table>

          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import create from "./NoteCreate.vue";
import axios from "axios";
export default {
  name: "Loan.note",
  components: { axios, create },
  props: ["loanAccountId", "reload"],
  data: function() {
    return {
      isLoading: false,
      filterShow: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "DESC",
        orderBy: "created_date",
        search: "",
        columns: "",
      },
      payment: {
        over: "0",
        under: "0",
        undueLoss: "0",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Тэмдэглэл", key: "body" },
        { label: "Огноо", key: "createdDate" },
        { label: "Бүртгэсэн", key: "createdBy" },
      ],
      isActiveLoan: false,
    };
  },
  created: function() {
    this.$data.query.columns = ["body", "created_date"].join(",");
    this.$data.query.id = this.loanAccountId;
    this.$data.download.header = {};
    for (let i in this.columns) {
      let _field = this.columns[i];
      this.$data.download.header[_field.label] = _field.key;
    }
    this.loadData();
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
    loanAccountId: {
      handler(loanAccountId) {
        this.$data.query.id = this.loanAccountId;
        this.loadData();
      },
      deep: true,
    },
    reload: {
      handler(reload) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      //console.log(this.$data.query);
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "LoanWebService/get_note_list", {
          params: this.$data.query,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;

            this.$data.items = response.body.data;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
            //console.log('################ END #####################');
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    submitSearch: function() {
      this.$data.query.search = this.$data.search;
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(
          this.$config.API_URL + "LoanWebService/get_note_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              search: this.$data.query.search,
              columns: this.$data.query.columns,
              id: this.$data.query.id,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        // //console.log(response)
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }
        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _item.createdBy = "@" + _item.createdBy;
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
  },
};
</script>
