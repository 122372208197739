var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"sm":"8"}},[(_vm.show)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',{attrs:{"header-tag":"header","footer-tag":"footer"}},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('h5',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Засварын гүйлгээ бүртгэх")])]),_c('div',[_c('b-form-group',{attrs:{"id":"input-group-payDescription","label":"Утасны дугаар","label-for":"input-username"}},[_c('money',{staticClass:"form-control",class:{
                          'border-danger': !_vm.selectCustomer,
                          'border-primary': _vm.selectCustomer,
                      },attrs:{"id":"input-username","type":"text","required":"","placeholder":"Утасны дугаар","suffix":"","thousands":"","precision":0},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1),(_vm.selectCustomer)?_c('div',[_c('b-alert',{attrs:{"show":""}},[_c('b-button',{staticClass:"pull-right",attrs:{"to":{
                          name: 'admin.customer.detail',
                          params: { id: _vm.selectCustomer.id },
                          },"target":"_blank","size":"sm","variant":"info"}},[_vm._v("Дэлгэрэнгүй")]),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.selectCustomer.lastname)+" "+_vm._s(_vm.selectCustomer.firstname)+" /"+_vm._s(_vm.selectCustomer.registerNumber)+"/ ")])],1),_c('b-form-group',{attrs:{"id":"input-","label":"Төрөл","label-for":"input-payDescription"}},[_c('b-form-select',{attrs:{"id":"input-payDescription","required":"","options":_vm.repair_types},model:{value:(_vm.form.repair_type),callback:function ($$v) {_vm.$set(_vm.form, "repair_type", $$v)},expression:"form.repair_type"}})],1)],1):_c('div',[_c('b-alert',{attrs:{"variant":"warning","show":""}},[_vm._v(" Хэрэглэгч байхгүй байна ")])],1),(_vm.form.repair_type)?_c('div',[(_vm.form.repair_type === 'LOAN_CLOSE_REPAIR')?_c('LoanCloseRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e(),(_vm.form.repair_type === 'LOAN_CLOSED_REPAIR')?_c('LoanClosedRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e(),(_vm.form.repair_type === 'OVERDUE_REPAIR')?_c('OverdueRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e(),(_vm.form.repair_type === 'RETURN_REPAIR')?_c('ReturnRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e(),(_vm.form.repair_type === 'DOUBLE_TAKE_REPAIR')?_c('DoubleTakeRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e(),(_vm.form.repair_type === 'BALANCE_REPAIR')?_c('BalanceRepair',{attrs:{"customer":_vm.selectCustomer}}):_vm._e()],1):_vm._e()],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }