<template>
  <div class="animated fadeIn">
    <b-card class="border-0 bg-gray-200 mr-n4 ml-n4 mt-n4">
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-row v-if="this.checkPermission('admin.dashboard.update')">
        <b-col class="mb-3">
          <b-dropdown split split-variant="outline-primary" :text="showType == 'LAST_WEEK' ? 'Сүүлийн 7 хоног' : 'Сүүлийн 6 сар'
        " variant="primary">
            <b-dropdown-item @click="changeShowType('LAST_WEEK')">Сүүлийн 7 хоног</b-dropdown-item>
            <b-dropdown-item @click="changeShowType('LAST_6_MONTH')">Сүүлийн 6 сар</b-dropdown-item>
          </b-dropdown>
          <b-button variant="primary" size="sm" class="pull-right" @click="loadData()">
            <i class="fa fa-refresh"></i> шинэчлэх
          </b-button>
          <p class="mb-0 pull-right mr-3 mt-1 text-muted">
            <strong>Сүүлд шинэчлэх огноо :</strong>
            {{ datetimeToDate(dashboard.last_update_date) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <h4>Орлогын үзүүлэлтүүд</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <Card label="Дундаж зээлийн хүү" bg_color="bg-primary" text_color="text-white" :data="dashboard.data[0]"
            value_type="percent"></Card>
        </b-col>
        <b-col md="3">
          <Card label="Жигнэсэн дундаж зээлийн хүү" bg_color="bg-primary" text_color="text-white"
            :data="dashboard.data[1]" value_type="percent"></Card>
        </b-col>
        <b-col md="3">
          <Card label="Хаагдсан зээлийн дундаж хугацаа" :data="dashboard.data[2]" value_type="month"></Card>
        </b-col>
        <b-col md="3">
          <Card label="Үлдэгдэлтэй байгаа зээлийн дундаж хугацаа" bg_color="bg-danger" text_color="text-white"
            :data="dashboard.data[3]" value_type="month"></Card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" :key="index" v-for="(item, index) in charts_data_income">
          <Chart :title="item.title" :data="[dashboard.data[item.index]]" :color="[item.color]" :startDate="startDate"
            :endDate="endDate" :showType="showType"></Chart>
        </b-col>
        <b-col md="6">
          <Chart title="Нийт үндсэн үйл ажиллагааны орлого " :data="[total_income_data]" :color="['#20c997']"
            :startDate="startDate" :endDate="endDate" :showType="showType"></Chart>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <h4>Зарлагын үзүүлэлтүүд</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" :key="index" v-for="(item, index) in charts_data_outcome">
          <Chart :title="item.title" :data="[dashboard.data[item.index]]" :startDate="startDate" :endDate="endDate"
            :showType="showType"></Chart>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Chart from "./Chart.vue";
import Card from "./Card.vue";
import moment from "moment";
import dashboard from "./data_financial.json";

export default {
  name: "dashboard-financial",
  components: { Chart, Card },
  data: function () {
    return {
      isLoading: false,
      showType: "LAST_WEEK",
      day_data: [],
      since_data: [],
      dashboard,
      total_income_data: [],
      startDate: moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      charts_data_income: [
        {
          title: "Зээл олголт",
          index: 4,
          permission: "admin.dashboard-financial.income.loan_amount",
          color: "#f86c6b",
        },
        {
          title: "Зээлийн үлдэгдэл ",
          index: 5,
          permission: "admin.dashboard-financial.income.loan_balance",
          color: "#ffc107",
        },
        {
          title: "Хуримтлагдсан үндсэн хүү",
          index: 6,
          permission: "admin.dashboard-financial.income.loan_interest_amount",
          color: "#f86c6b",
        },
        {
          title: "Төлөгдсөн үндсэн хүү ",
          index: 7,
          permission:
            "admin.dashboard-financial.income.loan_paid_interest_amount",
          color: "#067351",
        },
        {
          title: "Хуримтлагдсан нэмэгдүүлсэн хүү ",
          index: 8,
          permission: "admin.dashboard-financial.income.loan_undueloss_amount",
          color: "#f86c6b",
        },
        {
          title: "Төлөгдсөн хуримтлагдсан хүү ",
          index: 9,
          permission:
            "admin.dashboard-financial.income.loan_paid_undueloss_amount",
          color: "#067351",
        },
        {
          title: "Хугацаа хэтрэлтийн шимтгэл",
          index: 11,
          permission: "admin.dashboard-financial.income.overdue_free",
          color: "#f86c6b",
        },
        {
          title: "Төлөгдсөн хугацаа хэтрэлтийн шимтгэл",
          index: 12,
          permission: "admin.dashboard-financial.income.paid_overdue_fee",
          color: "#067351",
        },
        {
          title: "Олголтын шимтгэл",
          index: 10,
          permission: "admin.dashboard-financial.income.take_fee",
          color: "#067351",
        },
      ],
      charts_data_outcome: [
        {
          title: "Мессеж",
          index: 13,
          permission: "admin.dashboard-financial.outcome.sms",
        },
        {
          title: "Credo",
          index: 14,
          permission: "admin.dashboard-financial.outcome.credo",
        },
        {
          title: "Гүйлгээний шимтгэл",
          index: 15,
          permission: "admin.dashboard-financial.outcome.fee",
        },
        {
          title: "Qpay зардал",
          index: 16,
          permission: "admin.dashboard-financial.outcome.qpay",
        },
      ],
    };
  },
  created() {
    if (
      this.$store.state.dashboard_financial_data != undefined &&
      this.$store.state.dashboard_financial_data.data != undefined
    ) {
      this.$data.dashboard = this.$store.state.dashboard_financial_data;
      if (this.$data.dashboard.last_update_date != null) {
        this.$data.startDate = moment(this.$data.dashboard.last_update_date)
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        this.$data.endDate = moment(this.$data.dashboard.last_update_date)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
    }
    if (
      this.$data.dashboard.last_update_date == null &&
      this.checkPermission("admin.dashboard.update")
    ) {
      this.loadData();
    } else {
      this.calculationTotalIncome();
    }
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      this.$data.startDate = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      if (this.$data.showType == "LAST_6_MONTH") {
        this.$data.startDate = moment()
          .subtract(5, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
      }
      this.$data.endDate = moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      this.$http
        .post(
          this.$config.API_URL + "ReportWebService/get_dashboard_financial",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              startDate: this.$data.startDate,
              endDate: this.$data.endDate,
              groupType: this.$data.showType,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            if (response.body.status == 500) {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            } else if (response.body.status != 1) {
              this.$data.dashboard.data = response.body.data;
              this.$data.dashboard.last_update_date = moment().format(
                "YYYY-MM-DD HH:mm:ss"
              );
              this.calculationTotalIncome();
              if (this.$data.showType == "LAST_WEEK") {
                this.$store.commit("ADD_STATE", {
                  key: "dashboard_financial_data",
                  value: this.$data.dashboard,
                });
              } else {
                this.$store.commit("ADD_STATE", {
                  key: "dashboard_financial_data_month",
                  value: this.$data.dashboard,
                });
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    calculationTotalIncome: function () {
      var indexes = [7, 9, 10, 12];
      if (this.$data.dashboard.data.length > 4) {
        var result = [0, 0, 0, 0, 0, 0, 0];
        for (var i = 0; i < indexes.length; i++) {
          var tmp = this.$data.dashboard.data[indexes[i]];
          for (var j = 0; j < tmp.length; j++) {
            result[j] += parseFloat(tmp[j]);
          }
        }
        this.$data.total_income_data = result;
      }
    },
    datetimeToDate: function (data) {
      return moment(data).format("YYYY-MM-DD");
    },
    changeShowType: function (type) {
      this.$data.showType = type;
      if (this.$data.showType == "LAST_WEEK") {
        this.$data.startDate = moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        this.$data.endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.$data.dashboard = this.$store.state.dashboard_financial_data;
        this.calculationTotalIncome();
      } else {
        if (
          this.$store.state.dashboard_financial_data_month != undefined &&
          this.$store.state.dashboard_financial_data_month.data != undefined
        ) {
          this.$data.dashboard = this.$store.state.dashboard_financial_data_month;
          if (this.$data.dashboard.last_update_date != null) {
            this.$data.startDate = moment()
              .subtract(5, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            this.$data.endDate = moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD");
          }
          this.calculationTotalIncome();
        } else {
          this.loadData();
        }
      }
    },
  },
};
</script>