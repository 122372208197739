<template>
  <div>
    <span class="text-muted">Гэрээний дугаар</span>
    <h5 class="mb-0">
      <span v-if="customer.contractedNumber">{{
        customer.contractedNumber
      }}</span>
      <span v-else>-</span>

      <b-button size="sm" variant="primary" class="pull-right" @click="showModal">засах</b-button>
    </h5>

    <b-modal ref="my-modal" title="Утасны дугаар солих" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="save">
        <b-form-group label="Гэрээний дугаар:" label-for="input-change-phone">
          <b-form-input maxlength="7" pattern="\d{7}" id="input-change-phone" v-model="contractedNumber" required
            placeholder="Гэрээний дугаар" @keypress="isNumber($event)"></b-form-input>
        </b-form-group>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Customer.isSendSms",
  props: ["customer", "callback"],
  data: function () {
    return {
      contractedNumber: '',
      isLoading: false,
    };
  },
  watch: {
    'customer.contractedNumber': {
      handler(contractedNumber) {
        this.contractedNumber = contractedNumber
      },
      deep: true
    },
  },
  methods: {
    save: function () {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/set_contracted_number",
          {
            id: this.customer.id,
            contract_number: this.contractedNumber,
          },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.hideModal();
              this.callback(this.contractedNumber);
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>