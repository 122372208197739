<template>
  <div class="pull-left">
    <b-button variant="primary" size="sm" @click="showModal">Мэдээлэл өөрчлөх</b-button>

    <b-modal ref="my-modal" title="Хэрэглэгчийн мэдээлэл өөрчлөх" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="phoneSave">
        <b-form-group label="Овог:" label-for="input-change-lastname">
          <b-form-input id="input-change-lastname" v-model="form.lastname" required placeholder="Овог"></b-form-input>
        </b-form-group>

        <b-form-group label="Нэр:" label-for="input-change-firstname">
          <b-form-input id="input-change-firstname" v-model="form.firstname" required placeholder="Нэр"></b-form-input>
        </b-form-group>

        <b-form-group label="Регистр:" label-for="input-change-register">
          <b-input-group>
            <b-input-group-append>
              <b-select class="rounded-0" v-model="rd.char1" :options="chars.split('')"></b-select>
            </b-input-group-append>
            <b-input-group-append>
              <b-select class="rounded-0" v-model="rd.char2" :options="chars.split('')"></b-select>
            </b-input-group-append>
            <b-form-input maxlength="8" pattern="\d{8}" id="input-change-register" v-model="rd.number" required
              placeholder="Регистр" @keypress="isNumber($event)"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Утас: " label-for="input-change-phone1">
          <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone1" v-model="form.phone1" required
            placeholder="Утас" @keypress="isNumber($event)"></b-form-input>
        </b-form-group>

        <b-form-group label="Утас2:" label-for="input-change-phone2">
          <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone2" v-model="form.phone2" placeholder="Утас2"
            @keypress="isNumber($event)"></b-form-input>
        </b-form-group>
        <b-form-group label="Имэйл:" label-for="input-change-email">
          <b-form-input type="text" pattern="([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})"
            id="input-change-email" v-model="form.email1" placeholder="Имэйл"></b-form-input>
        </b-form-group>

        <hr />
        <b-form-group label="Тайлбар*:" label-for="input-change-description" class="text-info">
          <b-form-textarea type="text" id="input-change-description" v-model="form.description"
            class="border-info text-info" placeholder="Тайлбар" required></b-form-textarea>
        </b-form-group>

        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Customer.EditProfile",
  props: ["customerId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      form: {
        id: this.customerId,
        lastname: this.customer.lastname,
        firstname: this.customer.firstname,
        phone1: this.customer.phoneNumber,
        phone2: this.customer.emergencyNumber,
        type: "many_change",
        email1: this.customer.email,
        registerNumber: this.customer.registerNumber,
        description: "",
      },
      rd: {
        char1: "",
        char2: "",
        number: "",
      },
      chars: "АБВГДЕЁЖЗИЙКЛМНОӨПРСТУҮФХЦЧШЩЪЫЬЭЮЯ",
    };
  },
  watch: {
    rd: {
      handler(rd) {
        this.$data.form.registerNumber = rd.char1 + rd.char2 + rd.number;
      },
      deep: true,
    },
  },
  methods: {
    phoneSave: function () {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/change_data",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              // //console.log(response)
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal();
                this.callback();
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
    },
    showModal() {
      this.$data.rd = {
        char1: this.customer.registerNumber.substring(0, 1),
        char2: this.customer.registerNumber.substring(1, 2),
        number: this.customer.registerNumber.substring(2, 10),
      };
      this.$data.form = {
        id: this.customerId,
        lastname: this.customer.lastname,
        firstname: this.customer.firstname,
        phone1: this.customer.phoneNumber,
        phone2: this.customer.emergencyNumber,
        type: "many_change",
        email1: this.customer.email,
        registerNumber: this.customer.registerNumber,
      };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>