<template>
  <b-row>
    <b-col cols="12">
      <RegisterStarNew
        :customerId="customerId"
        :callback="reload"
        v-if="checkPermission('admin.customer.register-star')"
      ></RegisterStarNew>
    </b-col>
    <b-col cols="6">
      <b-card>
        <mini-table
          url="CustomerWebService/get_star_history_new"
          :fields="columns_star"
          :columns="[
            'amount',
            'percent',
            'isComplete',
            'completeDate',
            'createdDate',
          ]"
          :customerId="parseInt(this.customerId)"
          orderDirection="DESC"
          orderBy="created_date"
          :export="false"
          hover
          bordered
          fixed
          caption=""
          :hasSearch="false"
          title="Од авсан түүх"
          :refresh="refreshState"
        >
        </mini-table>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card>
        <mini-table
          url="CustomerWebService/get_increase_history"
          :fields="columns_increase"
          :columns="[
            'amount',
            'percent',
            'isComplete',
            'completeDate',
            'createdDate',
          ]"
          :customerId="parseInt(this.customerId)"
          orderDirection="DESC"
          orderBy="created_date"
          :export="false"
          hover
          bordered
          fixed
          caption=""
          :hasSearch="false"
          title="Эрхээ нэмсэн түүх"
          :refresh="refreshState"
        >
        </mini-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import RegisterStar from "./RegisterStar.vue";
import RegisterStarNew from "./RegisterStarNew.vue";
export default {
  name: "Customer.Star",
  props: ["customerId"],
  components: { RegisterStar, RegisterStarNew },
  data: function() {
    return {
      refreshState: false,
      refreshStateStar: false,
      columns_star: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Хувь",
          key: "percentage",
          sortable: true,
          sort_key: "percent",
        },
        {
          label: "Дүн",
          key: "amount",
          sortable: true,
          sort_key: "amount",
          class: "text-right",
        },
        {
          label: "Ашигласан эсэх",
          key: "isComplete",
          sortable: true,
          sort_key: "is_complete",
          class: "text-center",
        },
        {
          label: "Ашигласан огноо",
          key: "completeDate",
          sortable: true,
          sort_key: "complete_date",
        },
        {
          label: "Од авсан огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "created_date",
        },
      ],
      columns_increase: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Өмнөх зээлийн эрх",
          key: "prevAmount",
          sortable: true,
          sort_key: "prev_amount",
          class: "text-right",
        },
        {
          label: "Нэмэгдсэн дүн",
          key: "amount",
          sortable: true,
          sort_key: "amount",
          class: "text-right",
        },
        {
          label: "Бодит дүн",
          key: "realAmount",
          sortable: true,
          sort_key: "real_amount",
          class: "text-right",
        },
        {
          label: "Эрх нэмсэн огноо",
          key: "createdDate",
          sortable: true,
          sort_key: "created_date",
        },
      ],
    };
  },
  methods: {
    reload: function() {
      this.$data.refreshState = !this.$data.refreshState;
    },
  },
};
</script>
