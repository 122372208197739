<template>
  <div class="animated fadeIn">
    <b-card no-body no-focus>
      <b-tabs card>
        <b-tab title="Хувийн мэдээлэл" active>
          <b-card-text>
            <b-row>
              <b-col md="6">
                <b-card>
                  <h4 class="card-title text-uppercase text-primary">
                    #{{ loan.accountNumber }}
                  </h4>
                  <!-- <h4 class="mb-0"><small class="text-primary">{{ customer.registerNumber }} {{ customer.phoneNumber }}</small></h4> -->
                  <b-list-group class="mt-4">
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Төрөл</span>
                          <h5 class="mb-0">
                            {{ $store.getters.loan_type[loan.typeCode] }}
                          </h5>
                        </b-col>
                        <b-col sm="6" v-if="loan.merchantId"><span class="text-muted">Merchant</span>
                          <h5 class="mb-0">{{ loan.merchantName }}</h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Авсан огноо</span>
                          <h5 class="mb-0">{{ loan.loanDate }}</h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Дуусах огноо</span>
                          <h5 class="mb-0">{{ loan.expiryDate }}</h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Авсан дүн</span>
                          <h5 class="mb-0">{{ loan.loanAmount }}</h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Үлдэгдэл</span>
                          <h5 class="mb-0">{{ loan.loanBalance }} ₮</h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Хүү</span>
                          <h5 class="mb-0">{{ loan.interest }}%</h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Хугацаа</span>
                          <h5 class="mb-0">
                            {{ loan.term }} сар
                            <span v-if="loan.hasOwnProperty('noInterestMonth') &&
                      loan.noInterestMonth > 0
                      " class="text-danger">({{ loan.noInterestMonth }} сар)</span>
                          </h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Хаасан эсэх</span>
                          <h5 class="mb-0">
                            <b-badge variant="success" v-if="loan.isClosed">Тийм</b-badge>
                            <b-badge variant="danger" v-else>Үгүй</b-badge>
                          </h5>
                        </b-col>
                        <b-col sm="6" v-if="loan.isClosed"><span class="text-muted">Хаасан огноо</span>
                          <h5 class="mb-0">{{ loan.closedDate }}</h5>
                        </b-col>
                        <b-col sm="6" v-else><span class="text-muted">Хаах дүн</span>
                          <b-link v-b-modal="'modal-d'" class="mb-0">
                            <h5>{{ loan.closingAmount }}₮</h5>
                          </b-link>
                          <b-modal :id="'modal-d'" title="Задаргаа" hide-footer>
                            <template slot="default" class="mt-5">
                              <b-list-group class="text-left">
                                <b-list-group-item>Үндсэн зээл
                                  <span class="pull-right text-primary">{{ loan.loanBalance
                                    }}₮</span></b-list-group-item>
                                <b-list-group-item>Хуримтлагдсан хүү
                                  <span class="pull-right text-primary">{{ loan.interestAmount
                                    }}₮</span></b-list-group-item>
                                <b-list-group-item>Олголтын шимтгэл
                                  <span class="pull-right text-primary">{{ loan.takeFeeAmount
                                    }}₮</span></b-list-group-item>
                                <b-list-group-item>Алданги
                                  <span class="pull-right text-primary">{{ loan.unduelossAmount
                                    }}₮</span></b-list-group-item>
                              </b-list-group>
                            </template>
                          </b-modal>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Тайлбар</span>
                          <h5 class="mb-0">{{ loan.logDescription }}</h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Гэрээний дугаар</span>
                          <h5 class="mb-0">{{ loan.contractNumber }}</h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6">
                          <b-button size="sm" variant="primary" v-if="checkPermission('admin.loan.co-customer') &&
                      loan.coCustomerId
                      " :to="{
                      name: 'admin.customer.detail',
                      params: { id: loan.coCustomerId },
                    }" target="_blank">
                            хамтран
                          </b-button>
                        </b-col>
                        <b-col sm="6"></b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
              <b-col>
                <Related v-if="checkPermission('admin.loan.economist')"></Related>
                <Tag :loanAccountId="id"></Tag>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-card title="Тэмдэглэл">
                  <Note :loanAccountId="id" :reload="noteReload"></Note>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Задаргаа">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <RepaymentSchedule :loanId="id"></RepaymentSchedule>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Оюутны зээл" v-if="loan.typeCode == 'P3003' || loan.typeCode == 'P5003'">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <Student :loanId="id"></Student>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Барьцаа & Даатгал" v-if="loan.typeCode == 'P3001' || loan.typeCode == 'P5001'">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <Deposit :loanId="id"></Deposit>
                <hr />
                <DepositExtra :loanId="id" class="mt-3"></DepositExtra>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Барьцаа хөрөнгө" v-if="['P3002', 'P3007', 'P5002', 'P5007'].includes(loan.typeCode)">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <DepositWoman :loanId="id"></DepositWoman>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Барьцаа хөрөнгө">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <DepositInner :loanId="id"></DepositInner>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>

        <b-tab title="Санхүүгийн мэдээлэл">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <Finance :loanId="id" class="mt-3"></Finance>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import RepaymentSchedule from "./Detail/RepaymentSchedule.vue";
import Deposit from "./Detail/Deposit.vue";
import DepositWoman from "./Detail/DepositWoman.vue";
import DepositExtra from "./Detail/DepositExtra.vue";
import DepositInner from "./Detail/DepositInner.vue";
import Finance from "./Detail/Finance.vue";
import Note from "./Detail/Note.vue";
import Related from "./Detail/Related.vue";
import Student from "./Detail/Student.vue";
import Tag from "./Detail/Tag.vue";

export default {
  name: "Loan.Detail",
  components: {
    RepaymentSchedule,
    Deposit,
    DepositWoman,
    DepositInner,
    Note,
    Related,
    Student,
    Tag,
    DepositExtra,
    Finance,
  },
  data: function () {
    return {
      noteReload: false,
      isLoading: false,
      loan: {},
      id: this.$route.params.id,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "LoanWebService/get_loan_info", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.loan = response.body;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;
    },
  },
};
</script>