<template>
  <SainScoreFileList :datas="data" :register="registerNumber" :cus_info="cus_info"></SainScoreFileList>
</template>
    

<script>
import ZMS from "../../../Loan/Cib/ZMS.vue";
import SainScoreFileList from "../../../Loan/Cib/SainScoreFileList.vue";
import axios from "axios";
export default {
  name: "sales-new-list",
  components: {
    ZMS,
    SainScoreFileList
  },
  props: ["registerNumber"],
  data: function() {
    return {
      isLoading: false,
      items: [],
      data: [],
      cus_info: [],
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Нэр", key: "name" },
        { label: "Үүсгэсэн огноо", key: "createdDate" },
      ],
    };
  },
  watch: {
    registerNumber: {
      handler(registerNumber) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      // this.$http
      //   .post(
      //     this.$config.API_URL + "CibWebService/get_sain_score_list",
      //     { data: JSON.stringify({ registerNumber: this.registerNumber }) },
      //     {
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
      axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_sain_score_list",
        { registerNumber: this.registerNumber },
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
            }
          )
        .then(
          (response) => {
            this.isLoading = false;

            this.$data.data = response.data.list
            console.log(response.data.list)
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
