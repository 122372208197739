<template>

    <div>
        <b-card no-focus>
            <b-form-group id="input-loan-tag" label="Зээлийн таг" label-for="input-tag">

                <multiselect v-model="form.loan_tags" tag-placeholder="Add this as new tag" placeholder="Таг хайх"
                    label="name" track-by="code" :options="loan_tags" :multiple="true" :precision="0" :taggable="true"
                    @tag="addTag">
                </multiselect>
            </b-form-group>

            <b-form-group class="mb-0">
                <b-button variant="success" @click="saveLoanTag">Хадгалах</b-button>
            </b-form-group>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "Loan.Tag",
    props: ["loanAccountId"],
    data: function () {
        return {
            noteReload: false,
            isLoading: false,
            form: {
                loan_tags: []
            },
            loan_tags: [],
        };
    },
    created() {
        this.initData();
    },
    methods: {
        addTag(newTag) {
            console.log(newTag)
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
            }
            this.loan_tags.push(tag)
            this.$data.form.loan_tags.push(tag)
        },
        initData: function () {
            // this.$http
            //     .get(this.$config.API_URL + "LoanWebService/get_loan_tag_list", {
            //         params: {
            //             page: 1,
            //             per_page: 0,
            //             orderBy: "code",
            //         },
            //         headers: this.$store.getters.httpHeader,
            //         emulateJSON: true,
            //     })
                axios
                .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/get_loan_tag_list",
                    {
                        "page": 1,
                        "per_page": 0,
                        "orderBy": "code",
                    },
                    {
                    headers: {
                        ...this.$store.getters.httpHeader, // Include your custom headers
                        "Content-Type": "application/json", // Ensure JSON content type
                    },
                    }
                )
                .then(
                    (response) => {
                        this.isLoading = false;
                        console.log('hello')
                        console.log(response.data.data.list)
                        for (var i in response.data.data.list) {
                            if (response.data.data.list[i].isActive) {
                                this.$data.loan_tags.push({
                                    name: response.data.data.list[i].name,
                                    code: response.data.data.list[i].code,
                                    id: response.data.data.list[i].id
                                });
                            }
                        }
                    });

            // this.$http
            //     .get(this.$config.API_URL + "LoanWebService/get_selected_loan_tags", {
            //         params: {
            //             orderBy: "code",
            //             id: this.loanAccountId
            //         },
            //         headers: this.$store.getters.httpHeader,
            //         emulateJSON: true,
            //     })
                axios
                .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/get_selected_loan_tags",
                    {
                        "orderBy": "code",
                        "id": this.loanAccountId
                    },
                    {
                    headers: {
                        ...this.$store.getters.httpHeader, // Include your custom headers
                        "Content-Type": "application/json", // Ensure JSON content type
                    },
                    }
                )
                .then(
                    (response) => {
                        this.isLoading = false;
                        for (var i in response.data.data.list) {
                            if (response.data.data.list[i].isActive) {
                                this.$data.form.loan_tags.push({
                                    name: response.data.data.list[i].name,
                                    code: response.data.data.list[i].code,
                                    id: response.data.data.list[i].id
                                });
                            }
                        }
                    });

        },
        saveLoanTag: function () {
            let loan_tag_ids = []
            for (var i in this.$data.form.loan_tags) {
                loan_tag_ids.push(this.$data.form.loan_tags[i].id)
            }
            console.log(loan_tag_ids)

            this.isLoading = true;
            // this.$http
            //     .post(
            //         this.$config.API_URL + "LoanWebService/save_loan_tag",
            //         {
            //             id: this.loanAccountId,
            //             loan_tag_ids: loan_tag_ids.join(","),
            //         },
            //         { headers: this.$store.getters.httpHeader, emulateJSON: true }
            //     )
                axios
                .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/save_loan_tag",
                    {
                        id: this.loanAccountId,
                        loan_tag_ids: loan_tag_ids.join(","),
                    },
                    {
                    headers: {
                        ...this.$store.getters.httpHeader, // Include your custom headers
                        "Content-Type": "application/json", // Ensure JSON content type
                    },
                })
                .then(
                    (response) => {
                        this.isLoading = false;
                        console.log("save_tag")
                        console.log(response)
                        if (response.data.message == "success") {
                            console.log("Амжилттай")
                            this.showToast(
                                "Амжилттай",
                                "Амжилттай хадгалагдлаа",
                                "success"
                            );
                        } else {
                            let _ms = response.data.message;
                            this.showToast("Анхаар", _ms, "warning");
                        }
                    },
                    (response) => {
                        this.isLoading = false;
                        this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
                    }
                );
        }
    }
};


</script>