<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="4">
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <b-form-group label="Данс:">
            <b-select :options="account_list" v-model="account"></b-select>
          </b-form-group>

          <!-- Date Range Picker using two date pickers -->
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хуулга авах өдрөө оруулна уу"
          >
            <b-row>
              <b-col sm="6">
                <b-form-datepicker
                  v-model="startDate"
                  :max="endDate"
                  :format="dateFormat"
                  placeholder="Эхлэх огноо"
                ></b-form-datepicker>
              </b-col>
              <b-col sm="6">
                <b-form-datepicker
                  v-model="endDate"
                  :min="startDate"
                  :format="dateFormat"
                  placeholder="Дуусах огноо"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-form-group>

          <download-excel
              class="btn btn-primary"
              :fetch="fetchData"
              :fields="download.header"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :name="account + '_' + formattedDateRange + '.xls'">
                
                <b-spinner small type="grow" v-show="download.loading"></b-spinner> 
                <span v-show="download.loading" class="ml-1">Уншиж байна</span>
                <i class="fa fa-download" v-show="!download.loading"></i> 
                <span v-show="!download.loading" class="ml-1">Хуулга татах</span>
                
          </download-excel>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: "KhanDownload.list",
  data: function() {
    return {
      isLoading: false,
      account: '5434077338', 
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      account_list: [
        {
          'value': '5434077338',
          'text': 'Хаан банк - 5434077338'
        },
        {
          'value': '5034992840',
          'text': 'Хаан банк - 5034992840'
        },
        {
          'value': '8125102523',
          'text': 'Голомт банк - 8125102523'
        }
      ],
      transactions: [],
      download: {
        loading: false,
        header: {} 
      },
      dateFormat: 'YYYY-MM-DD',
    };
  },
  computed: {
    formattedDateRange() {
      const startDate = moment(this.startDate).format('YYYY-MM-DD');
      const endDate = moment(this.endDate).format('YYYY-MM-DD');
      return `${startDate}_${endDate}`;
    }
  },
  methods: {
    async fetchData() {
  this.updateDownloadHeader();

  if (this.download.loading) {
    this.download.loading = true;

    if (this.account === '') {
      this.showToast('Анхаар', 'Данс сонгоно уу', 'warning');
      this.download.loading = false;
      return [];
    }

    let response = null;
    const fromDate = moment(this.startDate).format('YYYY-MM-DD');
    const toDate = moment(this.endDate).format('YYYY-MM-DD');

    try {
      if (this.account === '8125102523') {
        response = await axios.post('https://service.numur.mn/bank-statement/account-statement', {
          from_date: fromDate,
          to_date: toDate,
        });
      } else {
        response = await axios.get('https://service.numur.mn/bank-statement/get_khan_statement', {
          params: {
            account: this.account,
            from_date: fromDate,
            to_date: toDate,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        });
      }

      // Check for error status and handle accordingly
      if (response.data.status === 500) {
        this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger');
        this.download.loading = false;
        return [];
      } else if (response.data.status === 404) {
        this.showToast('Алдаа', 'Хэтэрхий урт хугацаа байна', 'danger');
        this.download.loading = false;
        return [];
      } else if (response.data.status) {
        this.showToast('Алдаа', 'Хэтэрхий урт хугацаа эсвэл тодорхойгүй алдаа гарсан байна', 'danger');
        this.download.loading = false;
        return [];
      } else {
        // No error, return the data
        let _result = response.data.data;
        this.download.loading = false;
        return _result;
      }

    } catch (error) {
      console.error("Error fetching data:", error);
      this.showToast('Алдаа', 'Хэтэрхий урт хугацаа эсвэл тохиолдлын алдаа гарлаа. Дахин оролдоно уу', 'danger');
      this.download.loading = false;
      return [];
    }
  }

  return [];
},

    
    updateDownloadHeader() {
      if (this.account === '8125102523') {
        this.download.header = {
          'Гүйлгээний огноо': 'TxPostedDt',
          'Нэр': 'CtAcct',
          'Дебит гүйлгээ': 'Amt',
          'Эцсийн үлдэгдэл': 'Balance',
          'Гүйлгээний утга': 'TxAddInf',
          'NtryRef': 'NtryRef'
        };
      } else {
        this.download.header = {
          'Гүйлгээний огноо': 'transactionDate',
          'Салбар': 'branch',
          'Дебит гүйлгээ': 'debit',
          'Кредит гүйлгээ': 'credit',
          'Эцсийн үлдэгдэл': 'balance',
          'Гүйлгээний утга': 'description',
          'Харьцсан данс': 'relatedAccount'
        };
      }
    },

    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    }
  },
};
</script>
