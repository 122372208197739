<template>
    <div>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      
        <b-alert
            v-if="result" 
            show
            variant="danger bg-danger"
            class="mt-0 mb-3"
        >
            <strong>{{ result }}</strong>
        </b-alert>
      </div>
  </template>
  
  <script>
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import axios from 'axios';
  
  export default {
    name: "Customer.CheckJudge",
    props: ["customerId", "customer", "callback"],
    components: {
      Treeselect,
    },
    data: function() {
      return {
        isLoading: false,
        selected_type: false,
        result: "",
        resultType: "text-primary",
      };
    },

    created() {
        this.checkJudge()
    },
    methods: {
      checkJudge: function(is_new) {
        this.isLoading = true;
        this.$data.resultType = "text-primary";
        axios
          .post(
            this.$config.NUMUR_MS_ADMIN_URL + "customer/check_judge",
            {
              id: this.customerId,
            },
            {
                headers: {
                    ...this.$store.getters.httpHeader, // Include your custom headers
                    "Content-Type": "application/json", // Ensure JSON content type
                },
            }
          )
          .then(
            (response) => {
              if (response.data.message == "success") {
                const result = response.data.data
                if(result){
                    this.$data.result = "Тухайн хэрэглэгч шүүхэд шилжсэн байна.";
                    this.$data.resultType = "text-danger";
                } else {
                    this.$data.result = ""
                }
              } else {
                this.$data.resultType = "text-danger";
                this.$data.result = "Алдаа гарлаа";
              }
              this.isLoading = false;
            },
            (response) => {
              this.isLoading = false;
              this.$data.resultType = "text-danger";
              this.$data.result = "Алдаа гарсан байна";
            }
          );
      },
    },
  };
  </script>