<template>
  <div class="pull-right">
    <b-button variant="primary" @click="showModal">Шинээр үүсгэх</b-button>

    <b-modal ref="my-modal" title="Тэмдэглэл" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="save">
        <b-form-group label="" label-for="input-body">
          <b-form-textarea v-model="form.body" placeholder="Тайлбар"></b-form-textarea>
        </b-form-group>

        <hr />
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Loan.NoteCreate",
  props: ["loanAccountId", "customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      parent: null,
      subParent: null,
      form: {
        id: this.loanAccountId,
        body: "",
      },
      custom_type: false,
      selected_type: null,
      note_types: [],
      passion: [],
    };
  },
  watch: {
    loanAccountId: {
      handler(loanAccountId) {
        this.$data.form.id = this.loanAccountId;
      },
      deep: true,
    },
  },
  methods: {
    save: function () {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        //console.log(this.$data.form)
        this.$http
          .post(
            this.$config.API_URL + "LoanWebService/create_note",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Амжилттай хадгалагдлаа",
                  "success"
                );
                this.hideModal();
                this.callback();
              } else {
                let _ms = response.body.failureMessages.message;
                for (var i in _ms) {
                  this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                }
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          );
    },
    showModal() {
      this.$data.form = {
        id: this.loanAccountId,
        type: null,
        body: "",
      };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>