<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <!-- Loading spinner -->
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>

          <!-- Search and Filter Section -->
          <div class="mb-3">
            <div class="pull-right">
              <b-form-select
                v-model="query.per_page"
                :options="[5, 10, 25, 100]"
                v-if="items != undefined && items.length > 0"
              ></b-form-select>
            </div>
            <b-row>
              <b-col class="col-md-4">
                <b-form @submit.stop.prevent="submitSearch">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <i class="fa fa-search"></i>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="search"
                      placeholder="Хайх"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="loadData" type="button">Хайх</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button variant="secondary" @click="loadData">
                        <i class="fa fa-refresh"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </b-col>

              <!-- Trigger Button for Modal -->
              <b-button @click="showModal" variant="primary">Нэмэх</b-button>
            </b-row>
          </div>

          <!-- Table and Pagination -->
          <div class="table-responsive" v-if="items && items.length > 0">
            <b-table
              id="filter-table"
              stacked="md"
              @sort-changed="sortingChanged"
              hover
              bordered
              small
              responsive="sm"
              :items="items"
              :fields="columns"
              no-local-sorting
            >
              <template v-slot:cell(index)="data">
                <span style="width: 20px;">{{ data.index + pagination.from }}</span>
              </template>
              <template v-slot:cell(actions)="data">
                <b-button
                size="sm"
                variant="primary"
                :key="data.index"
                @click.prevent="getAndSetCanonicalUrl(data.item, data.index)"
              >
                Гэрээ
              </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  :key="data.index"
                  :to="{
                    name: 'admin.customer.detail',
                    params: { id: data.item.customer_id }
                  }"
                  target="_blank"
                >
                  <i class="fa fa-user"></i>
                </b-button>
              </template>
            </b-table>

            <!-- Pagination Footer -->
            <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page">
                <b-pagination
                  class="mb-0"
                  :total-rows="pagination.total"
                  :per-page="query.per_page"
                  v-model="query.page"
                />
              </nav>
            </div>
          </div>

          <!-- Alert if no data -->
          <b-alert
            variant="warning"
            v-if="hasSearched && (!items || items.length === 0 || error)"
            show
            class="mb-0"
          >
            Мэдээлэл оруулаагүй байна эсвэл мэдээлэл байхгүй байна
          </b-alert>
        </b-card>
      </b-col>
    </b-row>

    <!-- Modal for Form -->
    <b-modal v-model="isModalVisible" title="Гэрээ бүртгэх" @hide="resetForm">
      <b-form @submit.stop.prevent="submitForm">
        <!-- Select between Phone or Registered User Email -->
        <b-form-group label="Хайх төрөл">
          <b-form-select v-model="modalData.selection" :options="selectionOptions"></b-form-select>
        </b-form-group>

        <!-- Phone Number Field (only shown if "Phone" is selected) -->
        <b-form-group v-if="modalData.selection === 'phone'" label="Утасны дугаар">
          <b-form-input v-model="modalData.phone" placeholder="Утасны дугаар"></b-form-input>
        </b-form-group>

        <!-- Registered User Email Field (only shown if "Registered User Email" is selected and phone is null) -->
        <b-form-group v-if="modalData.selection === 'register'" label="Регистер">
          <b-form-input v-model="modalData.register" placeholder="Регистерийн дугаар"></b-form-input>
        </b-form-group>

        <!-- Contract Number Field -->
        <b-form-group label="Гэрээний дугаар">
          <b-form-input v-model="modalData.contract_number" placeholder="Гэрээний дугаар"></b-form-input>
        </b-form-group>

        <!-- File Upload Field -->
        <b-form-group label="Файл хадгалах">
          <b-form-file 
            v-model="modalData.attachedFile" 
            placeholder="Файл сонгох" 
            drop-placeholder="Drop a file here..." 
            accept=".pdf" 
          ></b-form-file>
        </b-form-group>

        <!-- Submit Button -->
        <b-button type="submit" variant="success">Хадгалах</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "admin.contract.file",
  data() {
    return {
      isLoading: false,
      query: {
        page: 1,
        per_page: 50,
        orderDirection: "DESC",
        orderBy: "createdDate",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      items: [],
      search: "", // Local search binding
      columns: [
        { label: '№', key: "index", class: "number_width text-center" },
        { label: 'Төрөл', key: "contract_type", sortable: true },
        {
          label: "Овог",
          key: "customer_lastname",
          sortable: true,
        },
        {
          label: "Нэр",
          key: "customer_name",
          sortable: true,
        },
        {
          label: "Утас",
          key: "phone_number"
        },
        {
          label: "Гэрээний дугаар",
          key: "contract_number",
          sortable: true,
        },
        {
          label: "Бүртгэсэн ажилтан",
          key: "registed_user_email"
        },
        {
          label: "Бүртгэсэн огноо",
          key: "created_date",
          sortable: true,
        },
        {
          label: "Яаралтай үед холбогдох дугаар",
          key: "emergency_number"
        },
        { label: "Үйлдэл", key: "actions", class: "text-center" },
      ],
      // New properties for modal
      isModalVisible: false, // Controls modal visibility
      modalData: {
        selection: null, // Default to "phone" field
        phone: '',
        register: '',
        contract_number: '',
        attachedFile: null,
      },
      selectionOptions: [
        { value: null, text: 'Сонгох' },
        { value: 'phone', text: 'Утас' },
        { value: 'register', text: 'Регистер' }
      ],
    };
  },
  created() {
    if (this.$route.query.search) {
      this.query.search = this.$route.query.search;
    }
  },
  methods: {
    submitSearch() {
      this.query.page = 1; // Reset to the first page on each search
      this.hasSearched = true;
      this.loadData(); // Fetch data with the updated search query
    },

    async loadData() {
      this.isLoading = true;
      // Reset items before loading new data
      this.items = [];

      const searchParam = this.search; // Search term (rd or phone)
      const apiUrl = this.$config.NUMUR_MS_ADMIN_URL + "search-contract";

      // Prepare query parameters
      let params = {
        page: this.query.page,
        per_page: this.query.per_page,
      };

      if (searchParam) {
        if (searchParam.match(/^\d{8,10}$/)) { // Assume phone number is numeric and 8-10 digits
          params.phone = searchParam;
        } else {
          params.rd = searchParam;
        }
      }

      // Make API call
      this.$http.get(apiUrl, { params })
        .then(response => {
          this.isLoading = false;
          if (response.data.success) {
            this.items = response.data.data;
            this.pagination.total = response.data.pagination.total;
            this.pagination.from = response.data.pagination.from;
            this.pagination.to = response.data.pagination.to;
          } else {
            this.showToast('Error', response.data.message || "No data found", 'danger');
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error(error);
        });

      // Update the URL with the current search term and pagination
      this.$router.push({ name: this.$route.name, query: this.query });
    },

    showModal() {
      this.isModalVisible = true; // Open modal
    },

    async getCanonicalUrl(contractFile) {
  try {
    // Ensure the contractFile is a valid URL
    if (!contractFile) {
      throw new Error('Invalid contract URL');
    }

    // Send a POST request with the URL in the body
    const response = await this.$http.post(this.$config.NUMUR_MS_ADMIN_URL +'cntrct-url', {
      url: contractFile // Send the base URL as body data
    });

    // Check if the response was successful and contains the signed URL
    if (response.data.success) {
      const signedUrl = response.data.data; // This should be the signed URL with query params
      console.log("Signed URL: ", signedUrl); // Log it to verify
      return signedUrl;  // Return the signed URL
    } else {
      console.error('Failed to get canonical URL:', response.data.message);
      this.showToast('Error', response.data.message || 'Failed to get canonical URL', 'danger');
      return null;
    }
  } catch (error) {
    console.error('Error fetching canonical URL:', error);
    this.showToast('Error', 'Failed to fetch the URL', 'danger');
    return null;
  }
},

async getAndSetCanonicalUrl(item, index) {
  try {
    const contractUrl = item.contract_file;  // Assuming the URL is in `contract_file`

    if (!contractUrl) {
      console.error('No contract file URL found:', item);
      return;
    }

    // Log the URL to check if it's being passed correctly
    console.log("Contract File URL: ", contractUrl);

    // Call your API to get the canonical URL (this is the signed URL)
    const canonicalUrl = await this.getCanonicalUrl(contractUrl);

    if (!canonicalUrl) {
      console.error('Failed to get canonical URL');
      return;
    }

    // Log the canonical URL
    console.log("Canonical URL: ", canonicalUrl);

    // Open the URL in a new tab
    window.open(canonicalUrl, '_blank');  // Open the URL in a new tab

    // Optionally, show a success toast notification
    this.showToast('Contract URL Opened', 'Successfully opened the contract in a new tab.', 'success');
    
  } catch (error) {
    console.error('Error fetching and opening the canonical URL:', error);
    this.showToast('Error', 'Failed to fetch or open the contract URL.', 'danger');
  }
},
async submitForm() {
  // Ensure either phone or register is set before submitting
  if (!this.modalData.phone && !this.modalData.register) {
    this.showToast('Error', 'Утасны дугаар эсвэл Регистерийн дугаар оруулна уу', 'danger');
    return;
  }

  // this.isLoading = true;
  const formData = new FormData();

  // Append all fields to the form data
  formData.append('contract_number', this.modalData.contract_number);
  formData.append('employee_email', 'example@company.com'); // Use real employee email if needed

  // Use phone if selected and not null, otherwise use register
  if (this.modalData.selection === 'phone' && this.modalData.phone) {
    formData.append('phone', this.modalData.phone);
  } else if (this.modalData.selection === 'register' || !this.modalData.phone) {
    formData.append('register', this.modalData.register);
  }

  if (this.modalData.attachedFile) {
    formData.append('upload_file', this.modalData.attachedFile);
  } else {
    this.showToast('Error', 'Гэрээний файл ороогүй байна', 'danger');
    return;
  }

  const apiUrl = `${this.$config.NUMUR_MS_ADMIN_URL}save-geree`;

  try {
    const response = await this.$http.post(apiUrl, formData);

    if (response.data.success) {
      this.showToast('Амжилттай хадгалсан', 'Гэрээ амжилттай хадгалагдлаа.', 'success');
      this.resetForm();  // Reset the form after successful submission
      this.isModalVisible = false;  // Close the modal
    } else {
      this.showToast('Алдаа', response.data.message || 'Гэрээ хадгалахад алдаа гарлаа', 'danger');
    }
  } catch (error) {
    console.error('Error saving contract:', error);
    // this.showToast('Алдаа', 'Гэрээ хадгалахад алдаа гарлаа', 'danger');
  } finally {
    this.isLoading = false;
  }
},
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.columns) {
        if (this.columns[i].key == ctx.sortBy) {
          this.query.orderBy = this.columns[i].key;
          break;
        }
      }
      this.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
      this.loadData(); // Trigger data reload when sorting changes
    },

    showToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>
