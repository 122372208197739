<template>
  <b-row>
    <b-col no-body class="col-md-3">
      <b-card>
        <b-list-group>
          <b-list-group-item
            :to="{
              name: $route.name,
              params: { id: $route.params.id, loanTypeId: item.loanTypeId },
            }"
            :key="index"
            v-for="(item, index) in customerGroups"
            >{{ item.customerGroupName }}</b-list-group-item
          >
        </b-list-group>
      </b-card>
    </b-col>
    <b-col>
      <b-card>
        <h4>Зээлийн нөхцөл</h4>
        <hr />
        <b-row>
          <b-col class="col-md-6">
            <loading
              :active.sync="loanConditionsLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :height="20"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <b-link
              v-if="this.$route.params.loanTypeId"
              @click="
                loanConditionEdit({
                  id: 0,
                  loanMinAmount: 0,
                  loanMaxAmount: 0,
                  interest: 0,
                  term: 0,
                  loanFeePercent: 0,
                  isActive: false,
                })
              "
              >нэмэх</b-link
            >
            <b-list-group class="mt-1">
              <b-list-group-item
                :key="index"
                v-for="(item, index) in loanConditions"
              >
                <ConfigConditionItem
                  :item="item"
                  :editback="loanConditionEdit"
                  :callback="getLoanConditions"
                ></ConfigConditionItem>
              </b-list-group-item>
            </b-list-group>
            <ConfigConditionEdit
              :item="editLoanCondition"
              :callback="getLoanConditions"
            ></ConfigConditionEdit>
          </b-col>
          <b-col class="col-md-6">
            <loading
              :active.sync="loanConditionsDtlLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :height="20"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <b-link
              v-if="this.$route.params.conditionId"
              @click="
                loanConditionDtlEdit({
                  id: 0,
                  interest: 0,
                  term: 0,
                  isActive: false,
                })
              "
              >нэмэх</b-link
            >
            <b-list-group class="mt-1">
              <b-list-group-item
                :key="index"
                v-for="(item, index) in loanConditionsDtl"
              >
                <ConfigConditionDtlItem
                  :item="item"
                  :editback="loanConditionDtlEdit"
                  :callback="getLoanConditionsDtl"
                ></ConfigConditionDtlItem>
              </b-list-group-item>
            </b-list-group>
            <ConfigConditionDtlEdit
              :item="editLoanConditionDtl"
              :callback="getLoanConditionsDtl"
            ></ConfigConditionDtlEdit>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ConfigConditionDtlItem from "./ConfigConditionDtlItem.vue";
import ConfigConditionItem from "./ConfigConditionItem.vue";
import ConfigConditionEdit from "./ConfigConditionEdit.vue";
import ConfigConditionDtlEdit from "./ConfigConditionDtlEdit.vue";

export default {
  data: function() {
    return {
      merchantId: this.$route.params.id,
      loanTypeId: null,
      conditionId: null,
      customerGroups: [],

      loanConditions: [],
      editLoanCondition: null,
      loanConditionsLoading: false,

      loanConditionsDtl: [],
      editLoanConditionDtl: null,
      loanConditionsDtlLoading: false,
    };
  },
  components: {
    ConfigConditionDtlItem,
    ConfigConditionItem,
    ConfigConditionEdit,
    ConfigConditionDtlEdit,
  },
  created() {
    this.getCustomerGroups();
    this.loadData();
  },
  watch: {
    $route(to, from) {
      this.loadData();
    },
  },
  methods: {
    loadData: function() {
      this.$data.loanTypeId = null;
      this.$data.loanConditions = [];
      if (this.$route.params.loanTypeId) {
        this.$data.loanTypeId = this.$route.params.loanTypeId;
        this.getLoanConditions();
      }
      this.$data.conditionId = null;
      this.$data.loanConditionsDtl = [];
      if (this.$route.params.conditionId) {
        this.$data.conditionId = this.$route.params.conditionId;
        this.getLoanConditionsDtl();
      }
    },
    getCustomerGroups: async function() {
      var response = await this.$http.get(
        this.$config.API_URL + "PurchaseWebService/get_customer_groups",
        {
          params: {
            merchantId: this.$data.merchantId,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      //console.log(response)

      this.$data.customerGroups = response.body.data;
    },
    getLoanConditions: async function() {
      this.$data.loanConditionsLoading = true;
      var response = await this.$http.get(
        this.$config.API_URL + "PurchaseWebService/get_loan_conditions",
        {
          params: {
            loanTypeId: this.$data.loanTypeId,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      this.$data.loanConditionsLoading = false;
      //console.log(response)

      this.$data.loanConditions = response.body.data;
    },
    getLoanConditionsDtl: async function() {
      this.$data.loanConditionsDtlLoading = true;
      var response = await this.$http.get(
        this.$config.API_URL + "PurchaseWebService/get_loan_conditions_dtl",
        {
          params: {
            conditionId: this.$data.conditionId,
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );
      this.$data.loanConditionsDtlLoading = false;
      //console.log(response)

      this.$data.loanConditionsDtl = response.body.data;
    },
    loanConditionEdit: function(_item) {
      this.$data.editLoanCondition = _item;
    },
    loanConditionDtlEdit: function(_item) {
      this.$data.editLoanConditionDtl = _item;
    },
  },
};
</script>
