<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
    </loading>
    <b-row>
      <b-col sm="12">
        <c-table selectable :url="'CustomerWebService/get_not_closed_cib_loan_list'" :per-page="10" :fields="columns"
          :columns="[
      'accountNumber',
      'lastname',
      'firstname',
      'register',
      'phone',
      'expirtDate',
      'loanBalance',
    ]" :filename="'Хаагдсан зээл'" hover bordered fixed caption="" :selectSubmitFunction="confitCib"
          :selectedButton="{ text: 'Илгээх', icon: 'fa fa-send' }"></c-table>
      </b-col>
    </b-row>

    <div>
      <b-modal ref="my-modal" size="xl" @ok="sentToCib">
        <template slot="modal-title">
          Баталгаажуулалт
        </template>
        <div class="d-block">
          <b-table stacked="md" hover bordered small responsive="sm" :items="selected" :fields="columns.slice(1)"
            no-local-sorting>
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(nicename)="data">
              {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
            </template>
            <template v-slot:cell(overdueDay)="data">
              <strong class="text-danger">
                {{ data.item.overdueDay }} өдөр</strong>
            </template>
            <template v-slot:cell(lastLoanClass)="data">
              <b-badge variant="warning">{{
      data.item.lastLoanClassName
    }}</b-badge>
              <small class="text-muted pull-right mt-1">{{
        data.item.lastLoanClassDate
      }}</small>
            </template>
          </b-table>
          <p class="text-danger mb-0">
            Та дээрх зээлийн мэдээллийг
            <strong>"Зээлийн мэдээллийн сан"</strong> - с хаахдаа итгэлтэй байна
            уу?
          </p>
        </div>
        <template slot="modal-footer"></template>
      </b-modal>
    </div>
    <b-modal ref="result-modal" size="xl">
      <template slot="modal-title">
        Үр дүн
      </template>
      <div class="d-block">
        <p class="text-danger">
          Та доорх зээлийн мэдээллийг
          <strong>"Зээлийн мэдээллийн сан"</strong> - руу хаах хүсэлт илгээсэн
          байна.
        </p>
        <b-table stacked="md" hover bordered small :items="responseData" :fields="responseColumn">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(customer)="data">
            {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname
            }}<br />
            <small class="text-muted">{{ data.item.register }}</small>
          </template>
          <template v-slot:cell(loan)="data">
            {{ data.item.loanAmount }}<br />
            <small class="text-muted">{{ data.item.expiryDate }}</small>
          </template>
          <template v-slot:cell(success)="data">
            <b-badge variant="success" v-if="data.item.success">Тийм</b-badge>
            <b-badge variant="danger" v-else>Үгүй</b-badge>
          </template>
        </b-table>
      </div>
      <template slot="modal-footer"></template>
    </b-modal>
  </div>
</template>

<script>
var convert = require("xml-to-json-promise");
import moment, { min } from "moment";
export default {
  name: "OverdueLoan.list",
  data: function () {
    return {
      columns: [
        { label: "", key: "checkbox" },
        { label: "№", key: "index", class: "text-center" },
        // { label: 'Данс', key: 'accountNumber' },
        {
          label: "Нэр",
          key: "nicename",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "register",
          sortable: true,
          sort_key: "register",
        },
        { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
        {
          label: "Дууссан хугацаа",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date",
        },
        // { label: 'Хэтэрсэн хоног', key: 'overdueDay', sortable: false },
        {
          label: "Авсан зээлийн дүн",
          key: "loanAmount",
          sortable: true,
          sort_key: "loan_amount",
          class: "text-right",
        },
        {
          label: "Зээлийн үлдэгдэл дүн",
          key: "loanBalance",
          sortable: true,
          sort_key: "loan_balance",
          class: "text-right",
        },
        { label: "ЗМС", key: "lastLoanClass" },
      ],
      responseColumn: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Хэрэглэгч", key: "customer" },
        { label: "Зээл", key: "loan" },
        { label: "Амжилттай эсэх", key: "success", class: "text-center" },
        { label: "Зээлийн код", key: "loancode", class: "text-left" },
        { label: "Алдаа", key: "error", class: "text-left" },
      ],
      selected: [],
      loanClasses: [],
      selectedTypeCibId: 0,
      isLoading: false,
      sendIndex: 0,
      responseData: [],
    };
  },
  methods: {
    confitCib: function (ids) {
      this.$data.selected = ids;
      this.$refs["my-modal"].show();
      this.$data.sendIndex = 0;
    },
    sentToCib: function () {
      this.isLoading = true;
      var selectedLoan = this.$data.selected[this.$data.sendIndex];
      this.$http
        .get(this.$config.APP_URL + "CompanyWebService/change_loan_balance", {
          params: { accountId: selectedLoan.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log(response)
            var xml = "<error>Алдаа гарсан байна</error>";
            if (response.body.responseResultType == "SUCCESS") {
              xml = response.body.successData;
            }
            convert.xmlDataToJSON(xml).then((json) => {
              var res = this.paseXmlData(json.response, selectedLoan);
              this.$data.responseData.push(res);
              if (res.success) {
                this.lastLoanClassSave(selectedLoan.id);
              }
            });
            this.$data.sendIndex++;
            if (this.$data.sendIndex < this.$data.selected.length)
              this.sentToCib();
            else {
              this.isLoading = false;
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.$refs["result-modal"].show();
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    paseXmlData: function (obj, loanData) {
      // //console.log(loanData);
      var result = {
        success: false,
        error: "",
        loancode: "",
        lastname: loanData.lastname,
        firstname: loanData.firstname,
        register: loanData.register,
        loanAmount: loanData.loanAmount,
        expiryDate: loanData.expiryDate,
      };
      if (obj.result == "OK") {
        if (obj.hasOwnProperty("loanresult")) {
          result.loancode = obj.loancode[0];
          if (obj.loanresult == "OK") {
            result.success = true;
          } else {
            var errors = [];
            for (var i in obj.errors) {
              errors.push(obj.errors[i].error[0]._);
            }
            result.error = errors.join(",");
          }
        }
      } else {
        var errors = [];
        for (var i in obj.errors) {
          errors.push(obj.errors[i].error[0]._);
        }
        result.error = errors.join(",");
      }
      return result;
    },
    lastLoanClassSave: function (loanAccountId) {
      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/save_loan_class",
          { loanId: loanAccountId, loanClassId: -1 },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then((response) => {
          // //console.log(response);
        });
    },
  },
};
</script>