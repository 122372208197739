<template>

    <div>
        <b-card no-focus>
            <b-form-group id="input-loan-tag" label="Хэрэглэгчийн таг" label-for="input-tag">

                <multiselect v-model="form.customer_tags" tag-placeholder="Add this as new tag" placeholder="Таг хайх"
                    label="name" track-by="code" :options="customer_tags" :multiple="true" :precision="0"
                    :taggable="true" @tag="addTag">
                </multiselect>
            </b-form-group>

            <b-form-group class="mb-0">
                <b-button variant="success" @click="saveLoanTag">Хадгалах</b-button>
            </b-form-group>
        </b-card>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "Loan.Tag",
    props: ["customerId"],
    data: function () {
        return {
            noteReload: false,
            isLoading: false,
            form: {
                customer_tags: []
            },
            customer_tags: [],
        };
    },
    created() {
        this.initData();
    },
    methods: {
        addTag(newTag) {
            console.log(newTag)
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
            }
            this.customer_tags.push(tag)
            this.$data.form.customer_tags.push(tag)
        },
        initData: function () {
    console.log('Initializing data');
    this.isLoading = true;

    // Fetch customer tags from the API
    axios.get(this.$config.NUMUR_MS_ADMIN_URL + 'get_customer_tag_list', {
        headers: this.$store.getters.httpHeader,  // Pass headers if required
    })
    .then((response) => {
        this.isLoading = false;

        if (response.data.success) {
            console.log('Fetched customer tags:', response.data);
            const tagList = response.data.list || [];  // Assuming 'data' contains the tags list
            
            // Filter active tags and map them to the structure expected by Vue component
            this.customer_tags = tagList.filter(tag => tag.isActive).map(tag => ({
                name: tag.name,
                code: tag.code,
                id: tag.id,
            }));

            // Populate the form with selected customer tags if any
            if (response.data.selected_tags) {
                this.form.customer_tags = response.data.selected_tags.filter(tag => tag.isActive).map(tag => ({
                    name: tag.name,
                    code: tag.code,
                    id: tag.id,
                }));
            }

        } else {
            this.showToast("Error", "Failed to fetch customer tags", "danger");
        }
    })
    .catch((error) => {
        this.isLoading = false;
        console.error('Error fetching customer tags:', error);
        this.showToast("Error", "Failed to fetch customer tags", "danger");
    });


    axios.get(this.$config.NUMUR_MS_ADMIN_URL + 'get_selected_customer_tags', {
        params: {
            id: this.customerId  // Pass customerId as a query parameter
        },
        headers: this.$store.getters.httpHeader  // Pass headers if required
    })
    .then((response) => {
        this.isLoading = false;

        if (response.data.success) {
            console.log('Fetched selected customer tags:', response.data);
            const tagList = response.data.list || [];  // Assuming 'list' contains the tags

            // Filter active tags and map them to the structure expected by Vue component
            this.form.customer_tags = tagList.filter(tag => tag.isActive).map(tag => ({
                name: tag.name,
                code: tag.code,
                id: tag.id,
            }));

            // Set the available customer tags
            // You can also fetch all tags if necessary and set them to `this.customer_tags`

        } else {
            this.showToast("Error", "Failed to fetch customer tags", "danger");
        }
    })
    .catch((error) => {
        this.isLoading = false;
        console.error('Error fetching customer tags:', error);
        this.showToast("Error", "Failed to fetch customer tags", "danger");
    });

        },
        saveLoanTag: function () {
            console.log('hello')
            let customer_tag_ids = []
            for (var i in this.$data.form.customer_tags) {
                customer_tag_ids.push(this.$data.form.customer_tags[i].id)
            }
            console.log(customer_tag_ids)

            this.isLoading = true;
            axios
                .post(
                    this.$config.NUMUR_MS_ADMIN_URL + "save_customer_tag",
                    {
                        id: this.customerId,
                        customer_tag_ids: customer_tag_ids.join(","),
                    },
                    { 
                        'Content-Type': 'application/json',  // Explicitly set Content-Type to application/json
                     ...this.$store.getters.httpHeader
                 }
                )
                .then(
                    (response) => {
                        this.isLoading = false;
                        console.log(response)
                        if (response.body.responseResultType == "SUCCESS") {
                            this.showToast(
                                "Амжилттай",
                                "Амжилттай хадгалагдлаа",
                                "success"
                            );
                        } else {
                            let _ms = response.body.failureMessages.message;
                            for (var i in _ms) {
                                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                            }
                        }
                    },
                    (response) => {
                        this.isLoading = false;
                        this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
                    }
                );
        }
    }
};


</script>