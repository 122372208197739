<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="4">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хамгийн ихдээ 90 хоног сонгоно уу"
          >
            <date-range-picker
              class="w-100"
              v-model="dateRange"
              :locale-data="locale"
              :opens="opens"
              :autoApply="true"
              :ranges="ranges"
              :timePicker="false"
            >
            </date-range-picker>
          </b-form-group>
          <b-form-group v-if="this.$store.state.user != null">
            <multiselect
              v-model="selectedOps"
              :options="operators"
              :searchable="true"
              :multiple="true"
              placeholder="Ажилтануудыг сонгоно уу"
              label="customerName"
              :hide-selected="true"
              :close-on-select="false"
              track-by="id"
            >
              <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag">
                  <span> @{{ option.firstname }} </span>
                  <i
                    aria-hidden="true"
                    tabindex="1"
                    @keypress.enter.prevent="remove(option)"
                    @mousedown.prevent="remove(option)"
                    class="multiselect__tag-icon"
                  ></i>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <span> @{{ props.option.firstname }} </span>
              </template>
            </multiselect>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col sm="8">
        <b-card>
          <b-list-group style="border-radius: 0">
            <ReportItem
              style="border-radius: 0"
              v-for="(_report, index) in reports.datas"
              :key="index"
              :dateRange="dateRange"
              :name="_report.name"
              :slug="_report.slug"
              :selectedOps="selectedOps"
              :header="_report.config.header"
              :index="new String(index + 1)"
              :children="_report.hasOwnProperty('child') ? _report.child : []"
            >
            </ReportItem>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";
import reports from "./_reports_note";
import ReportItem from "./ReportAuditItem.vue";
import axios from 'axios';

export default {
  name: "Report.list.month",
  components: {
    ReportItem,
    DateRangePicker,
    axios,
  },

  data: function() {
    return {
      download: {
        loading: false,
        loadingDetail: false,
      },
      today: moment().format("YYYY-MM-DD"),
      alwaysShowCalendars: false,
      linkedCalendars: false,
      ranges: {
        Өнөөдөр: [moment(), moment()],
        Өчигдөр: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Сүүлийн 30 хоног": [moment().subtract(29, "days"), moment()],
        "Сүүлийн 90 хоног": [moment().subtract(89, "days"), moment()],
        "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
        "Өмнөх сар": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month"),
        ],
      },
      reports: reports.list,
      dateRange: {
        startDate: moment()
          .subtract(89, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      selectedOps: [],
      operators: [],
      opens: "right",
      locale: {
        separator: " - ",
        format: "YYYY-MM-DD",
        customRangeLabel: "Өдөр сонгох",
        daysOfWeek: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
        monthNames: [
          "1-р сар",
          "2-р сар",
          "3-р сар",
          "4-р сар",
          "5-р сар",
          "6-р сар",
          "7-р сар",
          "8-р сар",
          "9-р сар",
          "10-р сар",
          "11-р сар",
          "12-р сар",
        ],
        firstDay: 1,
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {
    getStartDate() {
      return moment(this.dateRange.startDate).format("YYYY-MM-DD");
    },
    getEndDate() {
      return moment(this.dateRange.endDate).format("YYYY-MM-DD");
    },
  },

  methods: {
    initData: function() {
      // this.$http
      axios
        // .get(this.$config.API_URL + "ReportWebService/get_operator_list", {
          .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_operator_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response);
            this.operators = response.data.data;
            this.selectedOps = response.data.data;
          },
          (response) => {}
        );
    },
  },
};
</script>
