<template>
  <b-card>
    <b-row>
      <b-col>
        <div>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <div class="mb-3">
            <div class="pull-right">
              <b-form-select v-model="query.per_page" :options="[5, 10, 25, 100, 500]" v-if=" items != undefined && items.length > 0">          
              </b-form-select>
            </div>
          <b-row>
            
            <b-form @submit.stop.prevent="submitSearch" class="col-md-5 pl-0">
              <b-input-group >
                <b-input-group-prepend is-text><i class='fa fa-calendar'></i></b-input-group-prepend>
                <date-picker v-model="startAt" :config="{format:'YYYY-MM-DD', useCurrent: false}" placeholder="Эхлэх огноо"></date-picker>
                <date-picker v-model="endAt" :config="{format:'YYYY-MM-DD', useCurrent: false}" placeholder="Дуусах огноо"></date-picker>
                <b-input-group-append><b-button variant="primary" type="submit">Шүүх</b-button></b-input-group-append>
                <b-input-group-append><b-button variant="secondary" @click="loadData"><i class="fa fa-refresh"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form> 
          </b-row>
        </div>
        <div class="mb-0">
            <div
              class="table-responsive mb-0"
              v-if="items != undefined && items.length > 0"
            >
              <b-table
                stacked="md"
                hover
                bordered
                small
                responsive="sm"
                :items="items"
                :fields="columns"
                no-local-sorting
              >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(amountDebit)="data">
                  <span v-if="data.item.amount > 0">{{ data.item.amount | number }}</span>
                </template>

                <template v-slot:cell(amountCredit)="data">
                  <span v-if="data.item.amount < 0">{{ data.item.amount | number }}</span>
                </template>
              </b-table>

               <div slot="footer" class="mb-0 mt-3">
                <nav class="pull-right" v-if="pagination.total > query.per_page"><b-pagination class="mb-0" :total-rows="pagination.total" :per-page="query.per_page" v-model="query.page"/></nav>
                <p class="mb-0">{{ pagination.total }} өгөгдлөөс {{ (pagination.to)?pagination.from:0 }} - {{ pagination.to }} өгөгдөл харагдаж байна.</p>
              </div>  
            </div>

            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  name: "sales-new-list",
  props: ["customerId"],
  data: function() {
    return {
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: 'DESC',
        orderBy: 'created_date',
        startAt: '',
        endAt: '',
        columns:''
      },
      isLoading: false,
      items: [],
      pagination: {
        total: 0,
        from: 0,
        to: 0
      },
      orderDesc: false,
      startAt: '',
      endAt: '',
      download: {
        loading: false,
        header: {}
      },
      columns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Төрөл", key: "type" },
        { label: "+", key: "amountDebit", class:"text-right" },
        { label: "-", key: "amountCredit", class:"text-right" },
        { label: "Тайлбар", key: "description" },
        { label: "Шалтгаан", key: "userDescription" },
        { label: "Огноо", key: "createdDate" },
        { label: "Ажилтан", key: "createdBy" }
      ],
    };
  },
  created: function () {
    this.$data.query.customer_id = this.customerId;
    this.$data.download.header = {}
    for(let i in this.$data.columns) {
      let _field = this.$data.columns[i]
      this.$data.download.header[_field.label] = _field.key
    }
    this.loadData();
  },
  watch: {
    query: {
      handler (query) {
        this.loadData();
      },
      deep: true
    },
  },
  methods: {
    loadData: function() {
      console.log('aaaa')
      this.checkSession();
      this.isLoading = true;
      this.$http
        // .get(
        //   this.$config.API_URL + "CustomerWebService/get_customer_account_transaction",
        //   {
        //     params: this.$data.query,
        //     headers: this.$store.getters.httpHeader,
        //     emulateJSON: true,
        //   }
        // )
        axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_customer_account_transaction",
         this.$data.query ,
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
            }
          )
        .then(
          (response) => {
            console.log("get_customer_account_transaction")
            console.log(response)
            this.isLoading = false;
            this.$data.items = response.data.data.data;
            this.$data.pagination.total = response.data.data.total
            this.$data.pagination.from = response.data.data.from
            this.$data.pagination.to = response.data.data.to
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    submitSearch: function() {
      this.$data.query.startAt = this.$data.startAt,
      this.$data.query.endAt = this.$data.endAt
    },
    async fetchData(){
      if(this.download.loading) {
        this.download.loading = true;
        const response = await 
        // axios.get(this.$config.API_URL + 'CustomerWebService/get_customer_account_transaction',{
          axios.post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_customer_account_transaction",
          {
            "page": 1,
            "per_page": 0,
            "orderDirection": this.$data.query.orderDirection,
            "orderBy": this.$data.query.orderBy,
            "startAt": this.$data.query.startAt,
            "endAt": this.$data.query.endAt,
            "columns": this.$data.query.columns,
            "id": this.$data.query.id
          },
          {
            headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
            }
        );
        if(response.data.status == 500) {
          this.showToast('Алдаа', 'Алдаа гарсан байна. Дахин оролдоно уу', 'danger')
          this.download.loading = false;
          return []
        } 
        let _result = []
        for(let i in response.data.data) {
            let _item = response.data.data[i]
            _item.index = parseInt(i)+1
            if(_item.transactionType == 'LOAN') {
                _item.amount_loan = _item.amount;
                _item.amount_repayment = '';
            } else {
                _item.amount_repayment = _item.amount;
                _item.amount_loan = ''
                _item.bankName = ''
            }
          _result.push(_item)
        }
        return _result
      }
      return []
    },
    startDownload(){
        this.download.loading = true;
    },
    finishDownload(){
        this.download.loading = false;
    }
  },
};
</script>
