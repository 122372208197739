<template>
  <div>
    <b-alert show variant="danger bg-danger">
      Хэрэглэгчтэй гэрээ байгуулаагүй байна.
      <b-button class="ml-3" variant="secondary" size="sm" @click="showModal"
        v-if="checkPermission('admin.customer.manual-contract') && isManualContract == 2">Гэрээ байгуулах</b-button>
      <b-button class="ml-3" variant="secondary" size="sm" @click="showCibModal"
        v-if="checkPermission('admin.customer.manual-contract') && isManualContract == 1">ЗМС мэдээлэл
        оруулах</b-button>
    </b-alert>

    <b-modal ref="my-modal" title="Гэрээ байгуулах" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <p class="text-danger">
        Та хэрэглэгчийн гэрээг байгуулахдаа итгэлтэй байна уу?
      </p>
      <b-form @submit="phoneSave">
        <b-button class="pull-right" type="button" @click="hideModal" variant="danger">Болих</b-button>
        <b-button class="pull-right mr-3" type="submit" variant="primary">Тийм</b-button>
      </b-form>
    </b-modal>

    <b-modal ref="cib-modal" title="ЗМС мэдээлэл оруулах" hide-footer>
      <loading :active.sync="isCibLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="cibSave">
        <b-form-group label="">
          <b-form-checkbox v-model="cibForm.is_accept" name="is_accept" :value="true" :unchecked-value="false">
            Гэрээ байгуулж болох эсэх
          </b-form-checkbox>
        </b-form-group>
        <div v-if="cibForm.is_accept">
          <b-form-group label="Хэвийн">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.normal_count" type="text" suffix="" :precision="0"
                placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.normal_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Хугацаа хэтэрсэн">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.overdue_count" type="text" suffix=""
                :precision="0" placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.overdue_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Хэвийн бус">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.not_normal_count" type="text" suffix=""
                :precision="0" placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.not_normal_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Эргэлзээтэй">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.doubtful_count" type="text" suffix=""
                :precision="0" placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.doubtful_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Муу">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.bad_count" type="text" suffix="" :precision="0"
                placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.bad_amount" type="text" placeholder="Дүн"></money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Нийт">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.total_count" type="text" suffix="" :precision="0"
                placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.total_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
          <b-form-group label="Хаагдсан">
            <b-input-group>
              <money class="form-control text-right" v-model="cibForm.closed_count" type="text" suffix="" :precision="0"
                placeholder="Тоо"></money>
              <money class="form-control text-right" v-model="cibForm.closed_amount" type="text" placeholder="Дүн">
              </money>
            </b-input-group>
          </b-form-group>
        </div>

        <b-button class="pull-right" type="button" @click="hideCibModal" variant="danger">Болих</b-button>
        <b-button class="pull-right mr-3" type="submit" variant="primary">Тийм</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Customer.ManualContract",
  props: ["customerId", "customer", "callback", "isManualContract"],
  data: function () {
    return {
      isLoading: false,
      isCibLoading: false,
      form: { id: this.customerId },
      cibForm: {
        id: this.customerId,
        is_accept: false,
        normal_count: 0,
        normal_amount: 0,
        overdue_count: 0,
        overdue_amount: 0,
        not_normal_count: 0,
        not_normal_amount: 0,
        doubtful_count: 0,
        doubtful_amount: 0,
        bad_count: 0,
        bad_amount: 0,
        total_count: 0,
        total_amount: 0,
        closed_count: 0,
        closed_amount: 0,
      },
    };
  },
  methods: {
    phoneSave: function () {
      this.isLoading = true;

      this.$http
        .post(
          this.$config.APP_URL + "ReferenceWebService/create_contract",
          {
            isContract: "true",
            register: this.customer.registerNumber,
            email: this.$store.getters.email,
          },
          { emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            // //console.log(response)
            if (response.body.successData == "TRUE") {
              this.showToast(
                "Амжилттай",
                "Амжилттай гэрээ байгуулагдлаа",
                "success"
              );
            } else {
              var messages = response.body.failureMessages.message;
              for (var i in messages) {
                this.showToast("Анхаар", messages[i].failureMessage, "warning");
              }
            }
            this.callback();
            this.hideModal();
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    getUserOneLoginCode: function () {

    },
    cibSave: function () {
      this.isCibLoading = true;
      this.$data.cibForm.id = this.customerId;
      this.$data.cibForm.token = this.$store.getters.token;
      this.$data.cibForm.email = this.$store.getters.email;

      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/cib_data_save",
          { data: JSON.stringify(this.$data.cibForm) },
          { emulateJSON: true }
        )
        .then(
          (response) => {
            // //console.log(response);
            this.isCibLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast(
                "Амжилттай",
                "Амжилттай гэрээ байгуулагдлаа",
                "success"
              );
              this.callback();
              this.hideCibModal();
              this.showModal();
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
          },
          (response) => {
            this.isCibLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showCibModal() {
      this.$refs["cib-modal"].show();
    },
    hideCibModal() {
      this.$refs["cib-modal"].hide();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>