<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <b-form @submit.stop.prevent="createLoanRequest()">
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Утасны дугаар"
                label-for="input-username"
              >
                <money
                  id="input-username"
                  class="form-control"
                  v-model="form.username"
                  type="text"
                  required
                  placeholder="Утасны дугаар"
                  suffix=""
                  thousands=""
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Төлөлтийн өдөр"
                label-for="input-payDescription"
              >
                <b-form-select
                  :disabled="!selectCustomer"
                  type="number"
                  class="border-info"
                  id="input-payDescription"
                  v-model="form.repaymentDay"
                  placeholder="Төлөлтийн өдөр"
                  :options="[1, 5, 10, 15, 20, 25]"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <b-form-group
                id="input-group-prevLoan"
                label="Хугацаа сунгах зээл"
                label-for="input-prevLoan"
              >
                <multiselect
                  :disabled="!selectCustomer"
                  v-model="selected_account"
                  :loading="isLoadingAccounts"
                  :options="accounts"
                  :searchable="true"
                  :multiple="true"
                  placeholder="Хаах данс сонгох"
                  label="accountNumber"
                  :close-on-select="false"
                  :custom-label="showSelectedAccount"
                  track-by="id"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag">
                      <span>
                        <small>{{ option.accountNumber }}:</small> &nbsp;
                        <strong>{{ option.loanAmount + "₮" }}</strong>
                        {{ " (" + option.loanDate + ")" }}
                      </span>
                      <i
                        aria-hidden="true"
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span>
                      <small>{{ props.option.accountNumber }}:</small> &nbsp;
                      <strong class="text-danger">{{
                        props.option.loanAmount + "₮"
                      }}</strong>
                      {{ " (" + props.option.loanDate + ")" }}
                    </span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Сунгах зээлийн дүн"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="form.amount"
                  type="text"
                  required
                  placeholder="Дүн"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хүүний үлдэгдэл"
                label-for="input-payDescription"
                class="text-danger"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control border-danger text-danger"
                  v-model="form.interest_amount"
                  type="text"
                  required
                  placeholder="Дүн"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-create-loan"
                label="Хүүний үлдэгдэлийн хойшлуулах"
                label-for="input-status"
              >
                <b-form-checkbox
                  :disabled="!selectCustomer || !selected_account"
                  v-model="temp.interest_amount_add"
                  :value="true"
                  :unchecked-value="false"
                  button-variant=""
                >
                  Хамт тооцох
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Сунгаж эхлэх огноо"
                label-for="input-payDescription"
              >
                <date-picker
                  :disabled="!selectCustomer || !selected_account"
                  id="input-transactionDate"
                  v-model="form.date"
                  :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
                  required
                  placeholder="Зээл авсан огноо"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Хугацаа"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="form.term"
                  type="text"
                  required
                  placeholder=""
                  suffix=" сар"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-fee"
                label="Шимтгэлийн хувь"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="fee"
                  type="text"
                  required
                  placeholder="Дүн"
                  suffix=" %"
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                id="input-group-payDescription"
                label="Хүү"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="form.interest"
                  type="text"
                  required
                  placeholder=""
                  suffix=" %"
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mr-n3 ml-n3">
            <hr />
          </div>
          <b-row>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хүү хойшлуулах хугацаа"
                label-for="input-payDescription"
              >
                <select
                  :disabled="!selectCustomer || !selected_account"
                  name=""
                  class="form-control"
                  v-model="form.stop_term"
                >
                  <option
                    :value="i"
                    :key="key"
                    v-for="(i, key) in [0, 1, 2, 3, 4, 5, 6, 7]"
                    >{{ i }} сар</option
                  >
                </select>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Дуусах огноо"
                label-for="input-payDescription"
              >
                <div class="form-control">{{ form.new_date }}</div>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хүү бодох хоног"
                label-for="input-payDescription"
              >
                <div class="form-control">{{ temp.diff_day }}</div>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Шинэ хуваарь үүсгэх огноо"
                label-for="input-payDescription"
              >
                <div class="form-control">{{ form.end_date }}</div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хойшлуулсан хүүний хувь"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="form.stop_interest"
                  type="text"
                  required
                  placeholder=""
                  suffix=" %"
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="жилийн хүү"
                label-for="input-payDescription"
              >
                <div class="form-control">{{ temp.stop_interest_year }} %</div>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хүү төлөх төрөл"
                label-for="input-payDescription"
              >
                <select
                  :disabled="!selectCustomer || !selected_account"
                  v-model="form.stop_type"
                  class="form-control"
                >
                  <option value="INTEREST_FIRST">Хүү төлөх</option>
                  <option value="INTEREST_LAST">Хүү сүүлд нь шингээх</option>
                </select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                id="input-group-payDescription"
                label="Хүүний дүн"
                label-for="input-payDescription"
              >
                <money
                  :disabled="!selectCustomer || !selected_account"
                  id="input-fee"
                  class="form-control"
                  v-model="form.stop_total_amount"
                  type="text"
                  required
                  placeholder="Дүн"
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="mr-n3 ml-n3">
            <hr />
          </div>

          <GenerateRepaymentScheduleExtend
            class="pull-left ml-n3"
            :selectCustomer="selectCustomer"
            :coCustomer="coCustomer"
            :contract_number="contract_number"
            :repaymentDay="form.repaymentDay"
            :amount="form.amount"
            :fee="fee"
            :interest="form.interest"
            :term="form.term"
            :date="form.date"
            :noInterestTerm="form.noInterestTerm"
            :stop_date="form.new_date"
            :stop_amount="form.stop_total_amount"
            :stop_term="form.stop_term"
            :stop_type="form.stop_type"
          ></GenerateRepaymentScheduleExtend>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import GenerateRepaymentScheduleExtend from "./GenerateRepaymentScheduleExtend.vue";

export default {
  name: "new-loan",
  components: { GenerateRepaymentScheduleExtend },
  data: function() {
    return {
      selectCustomer: null,
      coCustomer: null,
      coUsername: "",
      isLoading: false,
      is_sms: false,
      is_repayment_day: false,
      tmp_is_sms: false,
      fee: 0.0,
      description: "",
      loanGroup: null,
      merchantId: null,
      contract_number: "",
      form: {
        repaymentDay: null,
        username: "",
        interest: 0.0,
        term: 1,
        stop_type: "INTEREST_FIRST",
        stop_term: 0,
        stop_amount: 0.0,
        stop_interest: 0.0,
        amount: 0,
        interest_amount: 0,
        stop_total_amount: 0,
        date: moment().format("YYYY-MM-DD"),
        new_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      isLoadingAccounts: false,
      purchase_ccounts: [],
      app_accounts: [],
      accounts: [],
      selected_account: null,
      scheduleList: [],
      loanAccountId: 0,
      temp: {
        interest_amount_add: false,
        diff_day: 0,
        stop_interest_year: 0.0,
      },
      stop_term_config: [0, 4.5, 4.7, 4.9, 5.0, 5.2, 5.4, 5.7],
    };
  },
  watch: {
    "form.username": {
      handler(username) {
        this.$data.is_repayment_day = false;
        this.$data.form.repaymentDay = null;
        this.$data.form.username = username.toString();
        if (username && username > 9999999) {
          this.getCustomerShort(username);
        }
      },
      deep: true,
    },
    "temp.interest_amount_add": {
      handler(interest_amount_add) {
        if (interest_amount_add) {
          this.$data.form.stop_total_amount =
            this.$data.form.stop_amount + this.$data.form.interest_amount;
        } else {
          this.$data.form.stop_total_amount = this.$data.form.stop_amount;
        }
      },
      deep: true,
    },
    "form.stop_amount": {
      handler(stop_amount) {
        if (this.$data.temp.interest_amount_add) {
          this.$data.form.stop_total_amount =
            this.$data.form.stop_amount + this.$data.form.interest_amount;
        } else {
          this.$data.form.stop_total_amount = this.$data.form.stop_amount;
        }
      },
      deep: true,
    },
    "form.interest_amount": {
      handler(interest_amount) {
        if (this.$data.temp.interest_amount_add) {
          this.$data.form.stop_total_amount =
            this.$data.form.stop_amount + this.$data.form.interest_amount;
        } else {
          this.$data.form.stop_total_amount = this.$data.form.stop_amount;
        }
      },
      deep: true,
    },
    "form.stop_term": {
      handler(stop_term) {
        this.generate_stop_data(stop_term);
      },
      deep: true,
    },
    selectCustomer: {
      handler(selectCustomer) {
        this.loadLoanAccounts(this.$data.form.username);
      },
      deep: true,
    },
    purchase_accounts: {
      handler(purchase_accounts) {
        //console.log(this.$data.purchase_accounts)
        //console.log(this.$data.app_accounts)
        this.$data.accounts = [
          ...this.$data.purchase_accounts,
          ...this.$data.app_accounts,
        ];
      },
    },
    app_accounts: {
      handler(app_accounts) {
        //console.log(this.$data.purchase_accounts)
        //console.log(this.$data.app_accounts)
        this.$data.accounts = [
          ...this.$data.purchase_accounts,
          ...this.$data.app_accounts,
        ];
      },
    },
    selected_account: {
      handler(selected_account) {
        // for(var i in selected_account) {
        //     this.$data.form.amount += parseInt(selected_account[i].loanBalance.replace(/,/g,''));
        // }
        this.get_interest_amount();
      },
    },
    "form.stop_interest": {
      handler(stop_interest) {
        this.$data.temp.stop_interest_year = (12 * stop_interest).toFixed(1);
        this.$data.form.stop_amount = parseInt(
          this.$data.form.amount *
            (this.$data.temp.stop_interest_year / 365 / 100) *
            this.$data.temp.diff_day
        );
      },
    },
    "form.repaymentDay": {
      handler(repaymentDay) {
        this.generate_stop_data(this.$data.form.stop_term);
      },
    },
  },
  created() {
    this.initData();
    if (this.$route.query.hasOwnProperty("p")) {
      this.$data.form.username = this.$route.query["p"];
    }
  },
  methods: {
    generate_stop_data: function(stop_term) {
      this.$data.form.stop_interest = this.$data.stop_term_config[stop_term];
      this.$data.temp.stop_interest_year = (
        12 * this.$data.form.stop_interest
      ).toFixed(1);
      var loanDate = moment(this.$data.form.date);
      var daysInMonth = parseInt(moment(this.$data.form.date).format("DD"));
      var lastDay = moment(loanDate.format("YYYY-MM-DD"));
      var k = 0;
      if (daysInMonth > this.form.repaymentDay) {
        lastDay = moment(
          lastDay
            .add(1, "month")
            .format(
              "YYYY-MM-" +
                (this.form.repaymentDay < 10 ? "0" : "") +
                this.form.repaymentDay
            )
        );
        var days = lastDay.diff(loanDate, "days");
        k = days >= 15 ? 1 : 2;
      } else {
        lastDay = moment(
          lastDay.format(
            "YYYY-MM-" +
              (this.form.repaymentDay < 10 ? "0" : "") +
              this.form.repaymentDay
          )
        );
        var days = lastDay.diff(loanDate, "days");
        k = days <= 15 ? 1 : 0;
      }
      var newDay = moment(
        loanDate
          .add(k + stop_term - 1, "month")
          .format(
            "YYYY-MM-" +
              (this.form.repaymentDay < 10 ? "0" : "") +
              this.form.repaymentDay
          )
      );
      var endDay = moment(newDay.format("YYYY-MM-DD")).add(1, "month");
      this.$data.form.new_date = newDay.format("YYYY-MM-DD");
      this.$data.form.end_date = endDay.format("YYYY-MM-DD");

      this.$data.temp.diff_day = moment(this.$data.form.new_date).diff(
        moment(this.$data.form.date),
        "days"
      );
      if (this.$data.temp.diff_day < 0) this.$data.temp.diff_day = 0;
      this.$data.form.stop_amount = parseInt(
        this.$data.form.amount *
          (this.$data.temp.stop_interest_year / 365 / 100) *
          this.$data.temp.diff_day
      );
    },
    initData: function() {
      this.$http
        .get(this.$config.API_URL + "PurchaseWebService/get_merchant_list", {
          params: {
            page: 1,
            per_page: 0,
            orderBy: "code",
            columns: [
              "code",
              "name",
              "register_number",
              "start_date",
              "end_date",
              "create_date",
            ].join(","),
          },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.merchants = [];
            for (var i in response.body.data) {
              if (response.body.data[i].isActive) {
                this.$data.merchants.push({
                  text:
                    "" +
                    response.body.data[i].code +
                    " - " +
                    response.body.data[i].name,
                  value: response.body.data[i].id,
                });
              }
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    get_interest_amount: function() {
      this.$data.form.interest_amount = 0;
      this.$data.form.amount = 0;
      for (var i in this.$data.selected_account) {
        this.$http
          .post(
            this.$config.API_URL + "LoanWebService/get_interest_amount",
            {
              loanId: this.$data.selected_account[i].id,
            },
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              this.isLoading = false;
              //console.log(response)
              var _data = response.body;
              if (_data.success) {
                this.$data.form.interest_amount += _data.total_fee_amount;
                this.$data.form.amount += _data.balance_amount;
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      }
    },
    loadLoanAccounts: function(_username) {
      if (this.$data.selectCustomer) {
        this.$data.accounts = [];
        this.isLoadingAccounts = true;
        this.$http
          .get(
            this.$config.API_URL + "CustomerWebService/get_customer_accounts",
            {
              params: {
                page: 1,
                per_page: 0,
                orderDirection: "ASC",
                orderBy: "loan_date",
                search: _username,
                columns: "phone",
              },
              headers: this.$store.getters.httpHeader,
            }
          )
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              this.app_accounts = [...response.body.data];
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );

        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_loan_list", {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: "ASC",
              orderBy: "loan_date",
              search: _username,
              columns: "phone",
            },
            headers: this.$store.getters.httpHeader,
          })
          .then(
            (response) => {
              this.isLoadingAccounts = false;
              this.$data.purchase_accounts = [...response.body.data];
            },
            (response) => {
              this.isLoadingAccounts = false;
            }
          );
      }
    },
    getCustomerShort: function(phone) {
      this.isLoading = true;
      this.$data.selectCustomer = null;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.selectCustomer = response.data;
              if (response.data.repaymentDay) {
                this.$data.form.repaymentDay = response.data.repaymentDay;
                this.$data.is_repayment_day = true;
              } else {
                this.$data.form.repaymentDay = null;
                this.$data.is_repayment_day = false;
              }
            } else {
              this.showToast(
                "Анхаар",
                phone + " утасны дугаартай хэрэгдэгчийн мэдээлэл байхгүй байна",
                "warning"
              );
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getCoCustomerShort: function(phone) {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL +
            "CustomerWebService/get_customer_short_info_by_register",
          {
            params: {
              phone: phone,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            if (response.data) {
              this.$data.coCustomer = response.data;
            } else {
              this.$data.coCustomer = false;
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    showSelectedAccount({ accountNumber, loanAmount, loanDate }) {
      return `${accountNumber} — ${loanAmount}₮ - ${loanDate}`;
    },
  },
};
</script>
