var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-nav',{staticStyle:{"margin-bottom":"-1px"},attrs:{"tabs":""}},[_c('b-nav-item',{attrs:{"active":_vm.selectedTypeCibcode == 0},on:{"click":function($event){return _vm.changeType(0, 10, 365000)}}},[_vm._v("Бүгд")]),_vm._l((_vm.loanClasses),function(item,index){return _c('b-nav-item',{key:index,attrs:{"active":_vm.selectedTypeCibcode == item.cibCode},on:{"click":function($event){return _vm.changeType(item.cibCode, item.minDay, item.maxDay)}}},[_vm._v(_vm._s(item.name))])})],2),_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('c-table',{attrs:{"selectable":"","url":'CustomerWebService/get_overdue_loan_list',"per-page":10,"fields":_vm.columns,"columns":[
    'accountNumber',
    'lastname',
    'firstname',
    'register',
    'phone',
    'expirtDate',
    'loanBalance',
  ],"filename":'Хугацаа хэтэрсэн зээл',"hover":"","bordered":"","fixed":"","caption":"","startDate":_vm.minDate,"endDate":_vm.maxDate,"selectSubmitFunction":_vm.confitCib,"selectedButton":{ text: 'Илгээх', icon: 'fa fa-send' }}})],1)],1),_c('div',[_c('b-modal',{ref:"my-modal",attrs:{"size":"xl"},on:{"ok":_vm.sentToCib}},[_c('template',{slot:"modal-title"},[_vm._v(" Баталгаажуулалт ")]),_c('div',{staticClass:"d-block"},[_c('b-table',{attrs:{"stacked":"md","hover":"","bordered":"","small":"","responsive":"sm","items":_vm.selected,"fields":_vm.columns.slice(1),"no-local-sorting":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(nicename)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.lastname.substr(0, 1) + "." + data.item.firstname)+" ")]}},{key:"cell(overdueDay)",fn:function(data){return [_c('strong',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(data.item.overdueDay)+" өдөр")])]}}])}),_c('p',{staticClass:"text-danger mb-0"},[_vm._v(" Та дээрх зээлийн мэдээллийг "),_c('strong',[_vm._v("\"Зээлийн мэдээллийн сан\"")]),_vm._v(" - руу ангилал өөрчлөх хүсэлт явуулахдаа итгэлтэй байна уу? ")])],1),_c('template',{slot:"modal-footer"})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }